import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Alert from "@mui/material/Alert";
import { ScaleLoader } from "react-spinners";
import { Cookies, useCookies } from "react-cookie";

const { BACKEND_API_URL } = require("../../../../env");

const Sect1 = () => {
  const [displayItem, setDisplayItem] = useState(false);
  const [visionnages, setVisionnages] = useState([]);
  const [currVisionnage, setCurrVisionnage] = useState({});
  const [loading, setLoading] = useState(true);
  const [itemsPerPage, setItemsPerPage] = useState(8);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [cookies2] = useCookies(['giga_partenaire']);
  const partenaireId = cookies2.giga_partenaire.idPartenaire;

  const closeChild = () => {
    setDisplayItem(false);
  };

  const handleVisionnage = (visionnage) => {
    setCurrVisionnage(visionnage);
    setDisplayItem(true);
  };



const searchHandler = () => {
  fetchVisionnages(searchTerm, 1);
};


  const fetchVisionnages = (searchTerm, page) => {
    setLoading(true);
    axios
      .get(`${BACKEND_API_URL}/api/video/getVideosByPartenaire2/${partenaireId}`, {
   
        params: {
          limit: itemsPerPage,
          page: page,
          searchTerm: searchTerm
        }
      })
      .then((response) => {
        setVisionnages(response.data.videos);
        setTotalPages(response.data.totalPages);
        console.log("totalPages: ", response.data.totalPages);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };


  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    setLoading(true);
    const limit1 = itemsPerPage;
    const page = pageNumber;
  
    axios.get(`${BACKEND_API_URL}/api/video/getVideosByPartenaire2/${partenaireId}`, {
      params: {
        limit: limit1,
        page: page,
        searchTerm: searchTerm,
      },
    })
      .then((response) => {
        setVisionnages(response.data.videos);
        console.log("dataVisionnage: ", response.data);
        setTotalPages(response.data.totalPages);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  
  

  useEffect(() => {
    fetchVisionnages(searchTerm, currentPage);
  }, []);

  return (
    <div className='visionnage_sect1_bottom'>
      <div className='partenaires_sect1_bottom'>
        <div className='partenaires_sect1_bottom_sect_top' style={{ height: 50 }}>
          <div>Mes vidéos</div>
          <div className="search-bar-container2">
            <input
              type="text"
              placeholder="Rechercher par intitule video"
              className="search-bar-hs2"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <button
              onClick={searchHandler}
              disabled={loading || searchTerm.length === 0}
              className={
                loading || searchTerm.length === 0
                  ? "search-button-disabled2"
                  : "search-button2"
              }
            >
              <i className="fa fa-search"></i>
            </button>
          </div>
        </div>

        <div className='partenaires_sect1_bottom_sect_table' style={{ overflowY: 'scroll', height: '350px' }}>
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Intitule video</th>
                <th>Total vues</th>
                <th>Durée vidéo</th>
                <th> Action </th>
              </tr>
            </thead>
            <tbody>
              {loading && (
                <ScaleLoader style={{
                  position: 'absolute',
                  top: '20vh',
                  left: '48%',
                }} color="#4EAC04" />
              )}
              {!loading && visionnages.map(visionnage => (
                <tr key={visionnage._id}>
                  <td>{visionnage._id}</td>
                  <td>{visionnage.intitulee}</td>
                  <td>{visionnage.visionnages.length}</td>
                  <td>{visionnage.duree}</td>
                  <td>
                    <i onClick={() => handleVisionnage(visionnage)} className="fa-solid fa-tv"></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="pagination-hs">
            <button
              className="pagination-button"
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <i className="fa fa-angle-left"></i>
            </button>
            <span className="current-page">
              {currentPage}/{totalPages}
            </span>
            <button
              className="pagination-button"
              onClick={() => paginate(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              <i className="fa fa-angle-right"></i>
            </button>
          </div>

          {displayItem && (
            <Details
              idVis={currVisionnage._id}
              intitulee={currVisionnage.intitulee}
              duree={currVisionnage.duree}
              nbDatas={currVisionnage.totalPointDonnee}
              packVideo={currVisionnage.packVideoCopy?.nom ?? ""}
              gigaRestant={currVisionnage.gigaRestant}
              nomPartenaire={currVisionnage.partenaire?.nomPartenaire ?? "NULL"}
              dateajout={currVisionnage.dateAjout}
              statut={currVisionnage.statutVideo ? 'Activer' : 'Desactiver'}
              prixCampagneVideo={currVisionnage.prixCampagneVideo}
              onClose={closeChild}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const Details = (props) => {
  // const [displayItem, setDisplayItem] = useState(true)
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const deleteVideo=()=>{
    setLoading(true)
    axios.delete(`${BACKEND_API_URL}/api/video/deleteVideo/${props.idVis}`)
    .then((res)=>{console.log(res)
    setShow(true)
    setLoading(false)
    window.location.reload()
    })
    .catch((e)=>console.log(e))
  }
  const date = new Date(props.dateajout); // Crée une instance de l'objet Date à partir de la prop 'date'

  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false
  };
  const formattedDate = `${date.toLocaleDateString('fr-FR', options)}`;
  const blockVideo =(id)=>{
    axios.put(`${BACKEND_API_URL}/api/video/video/${id}/block`)
    .then((res)=>{console.log(res)
      window.location.reload();
    
    })
    .catch((e)=>console.log(e))
    console.log("ok block")

  }
  const unBlockVideo =(id)=>{
    axios.put(`${BACKEND_API_URL}/api/video/video/${id}/unblock`)
    .then((res)=>{
      console.log(res)
    window.location.reload();
      
    })
    .catch((e)=>console.log(e))
    console.log("ok unblock")
  }
  const handleBlockClick = (id) => {
    blockVideo(id);

  }
  const handleUnBlockClick = (id) => {
    unBlockVideo(id);
    // window.location.reload();

  }
  return (
    <div className="sect3_elem_details">
      <div>
        {/* <FontAwesomeIcon  style={{color:"black"}}  icon="fa-solid fa-circle-xmark" /> */}
        <div onClick={props.onClose} className="elem_details_closure">
          {" "}
          <i className="fa-solid fa-xmark"></i>{" "}
        </div>

        <div className="part_details_div">
          <div>
            <div className="row1_details_header">
              <span style={{ color: "black" }}>
                <b> Détails Video </b>{" "}
              </span>
              {/* <span> {props.partDescription} </span> */}
              {show && (
              
              <Alert
                    severity="success"
                    style={{ position: "relative", left: 400, bottom: 150 }}
                  >
                    Video bien supprimer
                  </Alert>
                )}
            </div>

            <div className="row1_details_details">
              <br />

              <span style={{ color: "black" }}>
                {" "}
                <b>Détails</b>
              </span>

              <div>
                <span> ID : &nbsp; </span> <span> {props.idVis} </span>{" "}
              </div>
            
              <div>
                <span> Intitule Video : &nbsp; </span>{" "}
                <span> {props.intitulee} </span>{" "}
              </div>
              
              <div>
                <span> Duree Video : &nbsp; </span>{" "}
                <span> {props.duree} </span>{" "}
              </div>
              <div>
                <span> Point Donnée : &nbsp; </span>{" "}
                <span> {props.nbDatas} </span>{" "}
              </div>
              <div>
                <span> Type PackVideo : &nbsp; </span>{" "}
                <span> {props.packVideo} </span>{" "}
              </div>
              
            </div>
          </div>

          <div>
            <div className="row1_details_header">
              <span style={{ color: "black" }}>
                <b> Détails Video </b>{" "}
              </span>
              {/* <span> {props.partDescription} </span> */}
            </div>

            <div className="row1_details_details">
              <br />
              <span style={{ color: "black" }}>
                {" "}
                <b>Détails</b>
              </span>

              {/* <div>
                <span> Nom Partenaire : &nbsp; </span> <span> {props.nomPartenaire} </span>{" "}
              </div> */}
             
              {/* <div>
                <span> Description Activite : &nbsp; </span>{" "}
                <span> {props.partDescription} </span>{" "}
              </div> */}

              <div className="row_status_div">
              <span> Date Ajout: &nbsp; </span> 
              <span> {formattedDate} </span>{" "}

              </div>
              <div className="row_status_div">
              <span> Montant Pour Video: &nbsp; </span> 
              <span> {props.prixCampagneVideo} </span>{" "}

              </div>
              <div className="row_status_div">
              <span> Statu video: &nbsp; </span> 
              <span> {props.statut} </span>{" "}

              </div>
              {/* <div className="row_status_div" >
                <span>Choisir le statut:</span>
                <button style={{width:"80px",height:'25px',borderRadius:7,border:'none',backgroundColor:'red',fontWeight:'bold',color:"#ffff",cursor:"pointer"}} onClick={()=>handleBlockClick(props.idVis)}>Bloquer</button>
                <button style={{width:"90px",height:'25px',borderRadius:7,border:'none',backgroundColor:'green',fontWeight:'bold',color:"#ffff",cursor:'pointer'}} onClick={()=>handleUnBlockClick(props.idVis)}>Debloquer</button>
              </div> */}
            </div>
            <button style={{marginTop:400,height:30,width:100,borderRadius:7,border:"none",fontWeight:'bold',color:"#ffff",backgroundColor:'red', cursor:'pointer'}} onClick={()=>deleteVideo()}>Supprimer</button>
          </div>
        </div>
        {loading && (
                  <ScaleLoader
                    style={{
                      position: "absolute",
                      top: "50vh",
                      left: "70%",
                    }}
                    color="#4EAC04"
                  />
                )}
      </div>
    </div>
  );
};
export default Sect1;
