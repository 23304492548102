import React from 'react'
import Sect1 from './sub/dashboard/Sect1'
import Sect2 from './sub/dashboard/Sect2'
import Sect3 from './sub/dashboard/Sect3'

const Dashboard = () => {
  return (
    <section className='dashboard' >

      <Sect1 />
      <Sect2 />
      <Sect3 />

    </section>
  )
}

export default Dashboard