import React, { useState, useEffect, useContext } from "react";
import { RegContext } from "../../regContext/RegContext";
import Button from "@mui/material/Button";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Stack from "@mui/material/Stack";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import axios from "axios";
import { ScaleLoader } from "react-spinners";
import { UserContext } from "../context/UserContext";
const { BACKEND_API_URL } = require("../../../../env");

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Bilan = (props) => {
  return (
    <div className="sect1_bilan">
      <div>{props.count}</div>
      <span>{props.title}</span>
    </div>
  );
};

const theme = createTheme({
  palette: {
    neutral: {
      main: "#4EAC04",
      contrastText: "#fff",
    },
  },
});

const theme2 = createTheme({
  palette: {
    neutral: {
      main: "#901d09",
      contrastText: "#fff",
    },
  },
});

const Details = (props) => {
  // const [displayItem, setDisplayItem] = useState(true)

  // const [partError, setPartError] = useState(false);
  const { checkStatus, setCheckStatus } = useContext(RegContext);
  const [errorText, setErrorText] = useState("");
  const [partSuccess, setPartSuccess] = useState(false);
  const [successText, setSuccessText] = useState("");
  const [isValide, setIsValide] = useState(props.valide);
  const [partErrorText, setPartErrorText] = useState("");
  const [partError, setPartError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDeactivated, setIsDeactivated] = useState(props.desactive);
  // const handleError = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }
  //   setPartError(false);
  // };

  const handleSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    // setPartError(false);
    setPartSuccess(false);
  };

  const handleError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setPartError(false);
  };

  const handleDesactiveSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setDesactive(false);
  };

  const handleDesactiveError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setDesactiveError(false);
  };

  const handleReactiveSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setActif(false);
  };

  const handleReactiveError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setActifError(false);
  };

  const handleValidation = async () => {
    setLoading(true);

    Promise.all([
      axios.patch(
        `${BACKEND_API_URL}/api/partenaireValidation/${props.partId}`
      ),
      axios.post(`${BACKEND_API_URL}/api/sendMailValidation`, {
        emailPartenaire1: props.partEmail,
      }),
    ])
      .then(([validationPartenaire, sendMailValidation]) => {
        console.log(validationPartenaire.data);
        console.log(sendMailValidation.data);
        setPartSuccess(true);
        setSuccessText(sendMailValidation.data.msg);
        setIsValide(true);
        setLoading(false);
        setCheckStatus(!checkStatus);
      })
      .catch((error) => {
        console.log(error);
        setPartError(true);
        setPartErrorText(error.response.data.message);
        setLoading(false);
      });

    // try {

    //   const res = await axios.patch(
    //     `${BACKEND_API_URL}/api/partenaireValidation/${props.partId}`);

    //   const res = await axios.post("${BACKEND_API_URL}/api/sendMail", {
    //     emailPartenaire1: props.partEmail,
    //   });

    //   console.log(res.data);
    // setPartSuccess(true);
    // setSuccessText(res.data.message);
    // setIsValide(true);

    // } catch (error) {
    // console.log(error);
    // setPartError(true);
    // setErrorText("Erreur lors de la validation de l'inscription");
    // }
  };

  const [desactive, setDesactive] = useState(false);
  const [desactiveError, setDesactiveError] = useState(false);
  const [desactiveText, setDesactiveText] = useState("");
  const [desactiveErrorText, setDesactiveErrorText] = useState("");
  const [actif, setActif] = useState(false);
  const [actifError, setActifError] = useState(false);
  const [actifErrorText, setActifErrorText] = useState("");
  const [actifText, setActifText] = useState("");

  const handleDesactiver = async () => {
    setLoading(true);

    try {
      const res = await axios.patch(
        `${BACKEND_API_URL}/api/partenaireDesactivation/${props.partId}`
      );

      console.log(res.data);
      setDesactive(true);
      setDesactiveText(res.data.message);
      setIsDeactivated(true);
      setLoading(false);
      setCheckStatus(!checkStatus);
    } catch (error) {
      console.log(error);
      setDesactiveError(true);
      setDesactiveErrorText(error.response.data.message);
      setLoading(false);
    }
  };

  const handleReactiver = async () => {
    setLoading(true);

    try {
      const res = await axios.patch(
        `${BACKEND_API_URL}/api/partenaireReactivation/${props.partId}`
      );

      console.log(res.data);
      setActif(true);
      setActifText(res.data.message);
      setIsDeactivated(false);
      setLoading(false);
      setCheckStatus(!checkStatus);
    } catch (error) {
      console.log(error);
      setActifError(true);
      setActifErrorText(error.response.data.message);
      setLoading(false);
    }
  };

  return (
    <div className="sect3_elem_details">
      <div>
        {/* <FontAwesomeIcon  style={{color:"black"}}  icon="fa-solid fa-circle-xmark" /> */}
        <div onClick={props.onClose} className="elem_details_closure">
          {" "}
          <i className="fa-solid fa-xmark"></i>{" "}
        </div>

        <div className="part_details_div">
          <div>
            <div className="row1_details_header">
              <span style={{ color: "black" }}>
                {" "}
                <b> {props.partNom} </b>{" "}
              </span>
              <span> {props.partDescription} </span>
            </div>

            <div className="row1_details_details">
              <br />

              <span style={{ color: "black" }}>
                {" "}
                <b>Détails</b>
              </span>

              <div>
                <span> Partenaire : &nbsp; </span>{" "}
                <span> {props.partNom} </span>{" "}
              </div>
              <div>
                <span> Email 1 : &nbsp; </span> <span> {props.partEmail} </span>{" "}
              </div>
              <div>
                <span> Vues : &nbsp; </span> <span> {props.partEmail2} </span>{" "}
              </div>
              <div>
                <span> Activite : &nbsp; </span>{" "}
                <span> {props.partActivite} </span>{" "}
              </div>
              <div>
                <span> Télephone : &nbsp; </span> <span> {props.partTel} </span>{" "}
              </div>
              <div>
                <span> Numéro fixe : &nbsp; </span>{" "}
                <span> {props.partFixe} </span>{" "}
              </div>
            </div>
          </div>

          <div>
            {/* {!isValide && (
              <ThemeProvider theme={theme}>
                <Button
                  onClick={handleValidation}
                  color="neutral"
                  variant="contained"
                  endIcon={<CheckCircleIcon />}
                >
                  Valider Inscription
                </Button>
              </ThemeProvider>
            )} 
            <br />

            <span> Status : &nbsp; {isValide ? "Validé" : "Non validé"} </span>

            <br />

            <span> ID_partenaire : &nbsp; {props.partId} </span>
           

            <Stack spacing={2} sx={{ width: "70%" }}>
              <Snackbar
                open={partSuccess}
                autoHideDuration={3000}
                onClose={handleSuccess}
              >
                <Alert
                  onClose={handleError}
                  severity="success"
                  sx={{ width: "100%" }}
                >
                  {successText}
                </Alert>
              </Snackbar>
            </Stack> */}

            <Stack spacing={2} sx={{ width: "70%" }}>
              <Snackbar
                open={partError}
                autoHideDuration={3000}
                onClose={handleError}
              >
                <Alert
                  onClose={handleError}
                  severity="error"
                  sx={{ width: "100%" }}
                >
                  {partErrorText}
                </Alert>
              </Snackbar>
            </Stack>

            <Stack spacing={2} sx={{ width: "70%" }}>
              <Snackbar
                open={partSuccess}
                autoHideDuration={3000}
                onClose={handleSuccess}
              >
                <Alert
                  onClose={handleSuccess}
                  severity="success"
                  sx={{ width: "100%" }}
                >
                  {successText}
                </Alert>
              </Snackbar>
            </Stack>

            <Stack spacing={2} sx={{ width: "70%" }}>
              <Snackbar
                open={desactive}
                autoHideDuration={3000}
                onClose={handleDesactiveSuccess}
              >
                <Alert
                  onClose={handleSuccess}
                  severity="success"
                  sx={{ width: "100%" }}
                >
                  {desactiveText}
                </Alert>
              </Snackbar>
            </Stack>

            <Stack spacing={2} sx={{ width: "70%" }}>
              <Snackbar
                open={desactiveError}
                autoHideDuration={3000}
                onClose={handleDesactiveError}
              >
                <Alert
                  onClose={handleDesactiveError}
                  severity="error"
                  sx={{ width: "100%" }}
                >
                  {desactiveErrorText}
                </Alert>
              </Snackbar>
            </Stack>

            <Stack spacing={2} sx={{ width: "70%" }}>
              <Snackbar
                open={actif}
                autoHideDuration={3000}
                onClose={handleReactiveSuccess}
              >
                <Alert
                  onClose={handleReactiveSuccess}
                  severity="success"
                  sx={{ width: "100%" }}
                >
                  {actifText}
                </Alert>
              </Snackbar>
            </Stack>

            <Stack spacing={2} sx={{ width: "70%" }}>
              <Snackbar
                open={actifError}
                autoHideDuration={3000}
                onClose={handleReactiveError}
              >
                <Alert
                  onClose={handleReactiveError}
                  severity="error"
                  sx={{ width: "100%" }}
                >
                  {actifErrorText}
                </Alert>
              </Snackbar>
            </Stack>

            <div className="row1_details_header">
              <span style={{ color: "black" }}>
                {" "}
                <b> {props.partNom} </b>{" "}
              </span>
              <span> {props.partDescription} </span>
            </div>

            <div className="row1_details_details">
              <br />
              <span style={{ color: "black" }}>
                {" "}
                <b>Détails</b>
              </span>

              <div>
                <span> Ville : &nbsp; </span> <span> {props.partVille} </span>{" "}
              </div>
              <div>
                <span> Commune : &nbsp; </span>{" "}
                <span> {props.partCommune} </span>{" "}
              </div>
              <div>
                <span> Description Activite : &nbsp; </span>{" "}
                <span> {props.partDescription} </span>{" "}
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  // alignItems: "center",
                  width: "100%",
                  height: "20vh",
                  backgroundColor: "#f6f6f6",
                  borderRadius: "0.4rem",
                  position: "relative",
                  // justifyContent:"center",
                }}
                className="row_status_div"
              >
                <div>
                  <span> Status inscription : &nbsp; </span>{" "}
                  <span
                    className={
                      isValide
                        ? "row_status_div_valide"
                        : "row_status_div_nonvalide"
                    }
                  >
                    {" "}
                    {isValide ? "Validé" : "Non validé"}{" "}
                  </span>{" "}
                </div>

                <div
                  style={{
                    position: "absolute",
                    top: "13vh",
                    height: "4vh",
                    backgroundColor: "#f6f6f6",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {!isValide && (
                    <ThemeProvider theme={theme}>
                      <Button
                        onClick={handleValidation}
                        color="neutral"
                        variant="contained"
                        endIcon={<CheckCircleIcon />}
                      >
                        Valider Inscription
                      </Button>
                    </ThemeProvider>
                  )}

                  {isValide & isDeactivated && (
                    <ThemeProvider theme={theme}>
                      <Button
                        onClick={handleReactiver}
                        color="neutral"
                        variant="contained"
                        endIcon={<CheckCircleIcon />}
                      >
                        Réactiver
                      </Button>
                    </ThemeProvider>
                  )}

                  {!isDeactivated & isValide && (
                    <ThemeProvider theme={theme2}>
                      <Button
                        onClick={handleDesactiver}
                        color="neutral"
                        variant="contained"
                        endIcon={<CheckCircleIcon />}
                      >
                        Désactiver
                      </Button>
                    </ThemeProvider>
                  )}
                </div>
              </div>

              <div>
                <b> Status activité : &nbsp; </b>{" "}
                <b className={isDeactivated ? "partStatus2" : "partStatus1"}>
                  {" "}
                  {isDeactivated ? "désactivé" : "actif"}
                </b>{" "}
              </div>
            </div>

            {loading && (
              <ScaleLoader
                style={{
                  position: "absolute",
                  top: "12vh",
                  left: "48%",
                }}
                color="#4EAC04"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const Sect1 = () => {
  const { checkStatus, setCheckStatus } = useContext(RegContext);

  const [displayItem, setDisplayItem] = useState(false);
  const [partenaires, setPartenaires] = useState([]);
  // const [checkValid, setCheckValid] = useState(true);
  const { checkValid, setCheckValid, setTotalPartenaires,
    totalPartenaires, } = useContext(RegContext);
  const [currPartenaire, setCurrPartenaire] = useState({});
  const [loading, setLoading] = useState(false);
  const { prospects, setProspects } = useContext(RegContext);

  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(8);
  const [totalItems, setTotalItems] = useState(0);

  const closeChild = () => {
    setDisplayItem(false);
  };

  useEffect(() => {
    setLoading(true);
    const limit = 8;
    const pageNumber = currentPage;
    axios
      .get(
        `${BACKEND_API_URL}/api/partenaires?limit=${limit}&page=${pageNumber}&search=${searchTerm}`
      )
      .then((response) => {
        // console.log(response.data);
        // console.log("data returned : " + response.data.partenaires);
        const filteredPartenaires = response.data.partenaires.filter(
          (partenaire) => partenaire.valide === checkValid
        );
        setPartenaires(response.data.partenaires);
        setTotalItems(response.data.count);

        setTotalPartenaires(response.data.count);

        const filteredProspects = response.data.partenaires.filter(
          (partenaire) => partenaire.valide === false
        );

        setProspects(response.data.prospects);

        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [checkValid, checkStatus]);

  const setValidP = () => {
    setCheckValid(true);
  };

  const setInvalidP = () => {
    setCheckValid(false);
  };

  const handlePartenaire = (partenaire) => {
    console.log(partenaire);
    setCurrPartenaire(partenaire);
    setDisplayItem(true);
  };

  // code for pagination and search

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = partenaires
    .filter(
      (partenaire) =>
        partenaire.nomPartenaire
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        partenaire.activitePartenaire
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        partenaire.emailPartenaire1
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        partenaire.telPartenaire
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        partenaire.communePartenaire
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
    )
    .slice(indexOfFirstItem, indexOfLastItem);
  // console.log("currtItems: " + currentItems);

  // const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const [nombrePartenaire, setNombrePartenaire] = useState(0);
  const [nombreVideo, setNombreVideo] = useState(0);
  const [nombreVisionnage, setNombreVisionnage] = useState(0);

  useEffect(() => {
    const limit = 8;
    const pageNumber = currentPage;
    Promise.all([
      axios.get(
        `${BACKEND_API_URL}/api/partenaires?limit=${limit}&page=${pageNumber}&search=${searchTerm}`
      ),
      axios.get(`${BACKEND_API_URL}/api/video/getVideos`),
      axios.get(`${BACKEND_API_URL}/api/visionnage/getTotal`),
    ])
      .then(([dataPartenaire, dataVideos, dataVisionnage]) => {
        // console.log("returned data : "+dataPartenaire.data);
        const partenaires = dataPartenaire.data.partenaires;
        const count = dataPartenaire.data.count;
        setNombrePartenaire(count);

        const videosData = dataVideos.data;
        const count2 = videosData.length;
        setNombreVideo(count2);
        // console.log("videosData: " + videosData);
        // console.log(count2);

        const visionnages = dataVisionnage.data.total;
        // const count3 = visionnages.length;
        setNombreVisionnage(visionnages);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [checkStatus]);

  const filterDeactivated = () => {
    axios.get(`${BACKEND_API_URL}/api/partenaires`).then((response) => {
      // console.log(response.data);
      const filteredPartenaires = response.data.filter(
        (partenaire) => partenaire.desactive === true
      );
      setPartenaires(filteredPartenaires);
    });
  };

  const filterActif = () => {
    axios.get(`${BACKEND_API_URL}/api/partenaires`).then((response) => {
      // console.log(response.data);
      const filteredPartenaires = response.data.filter(
        (partenaire) =>
          partenaire.desactive === undefined || partenaire.desactive === false
      );
      setPartenaires(filteredPartenaires);
    });
  };

  const paginate = async (pageNumber) => {
    setLoading(true);
    setCurrentPage(pageNumber);

    const limit = 8;
    // const pageNumber = currentPage;

    try {
      const response = await axios.get(
        `${BACKEND_API_URL}/api/partenaires?limit=${limit}&page=${pageNumber}&search=${searchTerm}`
      );
      setPartenaires(response.data.partenaires);

      console.log("current partenaores: " + partenaires);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }


  };


  const searchHandler2 = (e) => {
    setLoading(true);
    const limit = itemsPerPage;
    const page = currentPage;
    // const pageNumber = page;

    console.log("searchTerm2: " + searchTerm);
    Promise.all([
      axios.get(
        `${BACKEND_API_URL}/api/partenaires?limit=${limit}&page=${page}&search=${searchTerm}`
      ),
    ])

      .then(([dataPartenaires]) => {
        // setHommesSandwich(dataHommeSandwich.data.allAddPartenaire);
        setPartenaires(dataPartenaires.data.partenaires);

        

        // console.log("searched: " + dataHommeSandwich.data.allAddPartenaire);
        // setTotalPages2(dataHommeSandwich.data.totalPages);
        setLoading(false);
      })

      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="partenaires_sect1">
      <div className="partenaires_sect1_top">
        <Bilan title="Partenaires inscrits" count={nombrePartenaire} />
        <Bilan title="Total vidéos" count={nombreVideo} />
        <Bilan title="Total visionnages" count={nombreVisionnage} />
        {/* <Bilan title=" " count="" /> */}
        {/* <Bilan title=" " count="" /> */}
      </div>

      <div className="partenaires_sect1_bottom">
        <div className="partenaires_sect1_bottom_sect_top">
          <div className="partStatusFilter">
            <button
              onClick={(e) => {
                filterDeactivated();
              }}
              style={{ backgroundColor: "#ef5a2b", color: "white" }}
            >
              Desactivés
            </button>
            <button
              onClick={(e) => {
                filterActif();
              }}
              style={{ backgroundColor: "#4EAC04", color: "white" }}
            >
              Actifs
            </button>
          </div>

          <div>Liste partenaires</div>

          {/* <button onClick={setValidP}>Valides</button>
          <button onClick={setInvalidP}>Non valides</button> */}
          <div>
            <div className=".search-bar-container2">
              <input
                type="text"
                placeholder="Rechercher..."
                className="search-bar-hs3"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <button 
              
              onClick={searchHandler2}

              className="search-button3">
                <i className="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>

        <div className="partenaires_sect1_bottom_sect_table">
          <table>
            <thead>
              <tr>
                <th>##</th>
                <th>Nom</th>
                <th>Vues</th>
                <th>Activite</th>
                <th>Telephone</th>
                <th>Status</th>
                <th> Action </th>
              </tr>
            </thead>
            <tbody>
              {loading && (
                <ScaleLoader
                  style={{
                    position: "absolute",
                    top: "12vh",
                    left: "48%",
                  }}
                  color="#4EAC04"
                />
              )}

              {partenaires.map((partenaire, index) => (
                <tr key={partenaire._id}>
                  <td>{partenaires.length - index}</td>
                  <td> {partenaire.nomPartenaire} </td>
                  <td>{partenaire.visionnages.length} </td>
                  <td>{partenaire.activitePartenaire}</td>
                  <td>{partenaire.telPartenaire}</td>
                  {/* <td>{partenaire.communePartenaire}</td> */}
                  <td>
                    {" "}
                    <b
                      className={
                        partenaire.desactive !== undefined &&
                        partenaire.desactive === true
                          ? "partStatus2"
                          : "partStatus1"
                      }
                    >
                      {" "}
                      {partenaire.desactive !== undefined &&
                      partenaire.desactive === true
                        ? "desactive"
                        : "actif"}{" "}
                    </b>{" "}
                  </td>

                  <td>
                    {" "}
                    <i
                      onClick={() => handlePartenaire(partenaire)}
                      className="fa-solid fa-tv"
                    ></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="pagination">
            <button
              className="pagination-button"
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <i className="fa fa-angle-left"></i>
            </button>
            <span className="current-page">
              {currentPage}/{Math.ceil(totalItems / itemsPerPage)}
            </span>
            <button
              className="pagination-button"
              onClick={() => paginate(currentPage + 1)}
              disabled={currentPage === Math.ceil(totalItems / itemsPerPage)}
            >
              <i className="fa fa-angle-right"></i>
            </button>
          </div>

          {displayItem && (
            <Details
              valide={currPartenaire.valide}
              desactive={currPartenaire.desactive}
              partId={currPartenaire._id}
              partNom={currPartenaire.nomPartenaire}
              partEmail={currPartenaire.emailPartenaire1}
              partEmail2={currPartenaire.visionnages.length}
              partActivite={currPartenaire.activitePartenaire}
              partTel={currPartenaire.telPartenaire}
              partFixe={currPartenaire.fixePartenaire}
              partDescription={currPartenaire.descriptionPartenaire}
              partVille={currPartenaire.villePartenaire}
              partCommune={currPartenaire.communePartenaire}
              onClose={closeChild}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Sect1;
