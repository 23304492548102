import React, { useState, useEffect } from "react";
import partner_logo from "./images/yango_logo.png";
import partner_logo2 from "./images/djamo_logo.png";
import partner_logo3 from "./images/eco_logo.jpg";
import axios from "axios";
import {ScaleLoader} from "react-spinners";
import { Cookies, useCookies } from "react-cookie";

const { BACKEND_API_URL } = require("../../../../env");


const Partner = (props) => {

  return (
    <div className="sect2_partner">
      <div>
        <b>{props.partner}</b>
        <b>
          {props.visionnages} <i className="fa-solid fa-eye"></i>{" "}
        </b>
      </div>
      <div>
        <img className="partner_logo" src={props.logo} alt="" />
      </div>
    </div>
  );
};

const Sect2 = ({  }) => {
  const [partenaire, setPartenaire] = useState(null);
  const [loading, setLoading] = useState(false);
  const [cookies2] = useCookies(['giga_partenaire']);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${BACKEND_API_URL}/api/getPartenaireById/${cookies2.giga_partenaire.idPartenaire}`)
      .then((response) => {
        setPartenaire(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
        setLoading(false);
      });
  }, []);


  return (
    <div className="sect2">
      {loading && (
        <ScaleLoader
          style={{
            position: 'absolute',
            top: '7vh',
            left: '27%',
          }}
          color="#4EAC04"
        />
      )}

      {partenaire && (
        <Partner
          key={partenaire._id}
          partner={
            partenaire.nomPartenaire.length > 7
              ? partenaire.nomPartenaire.substring(0, 7) + "..."
              : partenaire.nomPartenaire
          }
          visionnages={partenaire.visionnages.length}
          logo={`data:image/png;base64,${btoa(String.fromCharCode(...new Uint8Array(partenaire.logo.data.data)))}`}
        />
      )}
    </div>
  );
};

export default Sect2;


