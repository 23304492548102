import React, {useState, useEffect, useContext} from 'react'
import banner_bg from "./images/home_banner.png"
import axios from 'axios'
import {ScaleLoader} from "react-spinners";
import { RegContext } from "../../regContext/RegContext";
import { Cookies, useCookies } from "react-cookie";

const { BACKEND_API_URL } = require("../../../../env");




const Bilan = (props) => {
  return (
    <div className='sect1_bilan' >

      <div>{props.count}</div>
      <span>{props.title}</span>
        
    </div>
  )
}

const Sect1 = () => {

  const [nombrePartenaire, setNombrePartenaire] = useState(0);
  const [nomrebHommeSandwich, setNombreHommeSandwich] = useState(0);
  const [nombreVisionnage, setNombreVisionnage] = useState(0);
  const [nombreUsagers, setNombreUsagers] = useState(0);
  const [nombreVideos, setNombreVideos] = useState(0)
  const [cookies2] = useCookies(['giga_partenaire']);

  const [visionnagesParM, setVisionnagesParM] = useState(0)
  const [visionnagesParF, setVisionnagesParF] = useState(0)
  const {
    setTotalPartenaires,
    totalPartenaires,
    totalUsagers,
    setTotalUsagers,
  } = React.useContext(RegContext);

  useEffect(() => {
    const partenaireId = cookies2.giga_partenaire.idPartenaire;

    Promise.all([
      // axios.get(`${BACKEND_API_URL}/api/partenaires`),
      axios.get(`${BACKEND_API_URL}/api/visionnages/partenaire/${partenaireId}`,{
        limit: 1000,
        page: 1,
        search : ""
      }),
      axios.get(`${BACKEND_API_URL}/api/video/getVideosByPartenaire2/${partenaireId}`),
    ])
      .then(([dataVisionnage, dataVideos]) => {
        const videos = dataVideos.data;

        setNombreVideos(videos.countVideos);
        // console.log("videos: ", dataVideos.data);

        setNombreVisionnage(dataVisionnage.data.countVisionnages);

        const visionnages = dataVisionnage.data.viewings;
        // const count3 = visionnages.length;
        // setNombreVisionnage(count3);

        const visionnagesParMData = visionnages.filter(
          (visionnage) => visionnage.usager?.sexe === "masculin"
        );

        const visionnagesParFData = visionnages.filter(
          (visionnage) => visionnage.usager?.sexe === "féminin"
        );

        setVisionnagesParM(visionnagesParMData.length);
        setVisionnagesParF(visionnagesParFData.length);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, [cookies2]);

  return (
    <div className='sect1' >

      <div>
            <Bilan title="Nombre total de vues" count={nombreVisionnage} />
            <Bilan title="Nombre de videos" count={nombreVideos} />

               <Bilan title="Visionnages par masculin" count={visionnagesParM} />
            <Bilan title="Visionnages par feminin" count={visionnagesParF} />
      </div>
      <div style={{ backgroundImage: `url(${banner_bg})` }} >

      </div>
        
    </div>
  )
}

export default Sect1