import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { toastPlacements } from "rsuite/esm/toaster/ToastContainer";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { RegContext } from "../../regContext/RegContext";


const { BACKEND_API_URL } = require("../../../../env");

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const Details = (props) => {
  // const [displayItem, setDisplayItem] = useState(true)
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    // setOpen(false);
    setRetraitError(false);
  };

  const {   retraitStatus,
    setRetraitStatus } = useContext(RegContext);
  const [refTerm, setRefTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [retraitError, setRetraitError] = useState(false);
  // const [open, setOpen] = useState(true);
// 

  const handleUpdateRetrait = async () => {
    setLoading(true);
    try {
      const response = await axios.put(`${BACKEND_API_URL}/api/retrait/${props.id}`, {
        refPaiment: refTerm,
        email: props.email,
      });

      console.log(response.data); // Handle the response as needed
      setRetraitStatus(true);
      setRetraitError(true);
      // Optionally, you can add a success message or update the UI accordingly
    } catch (error) {
      console.error("There was an error updating the retrait!", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="sect3_elem_details">

      
      <div>
        {/* <FontAwesomeIcon  style={{color:"black"}}  icon="fa-solid fa-circle-xmark" /> */}
        <div onClick={props.onClose} className="elem_details_closure">
          {" "}
          <i className="fa-solid fa-xmark"></i>{" "}


          <Stack spacing={2} sx={{ width: "70%" }}>
              <Snackbar
                open={retraitError}
                autoHideDuration={2000}
                onClose={handleClose}
              >
                <Alert
                  onClose={handleClose}
                  severity="success"
                  sx={{ width: "100%" }}
                >
                 Retrait confirmé avec succès, un email de confirmation a été envoyé à l'utilisateur
                </Alert>
              </Snackbar>
            </Stack>
        </div>

        <div className="part_details_div">

          
          <div>
            <div className="row1_details_header">
              <span style={{ color: "black" }}>{/* <b> </b>{" "} */}</span>
              {/* <span> {props.partDescription} </span> */}
            </div>

            <div className="row1_details_details">
              <br />

              <span style={{ color: "black" }}>
                {" "}
                <b>Détails</b>
              </span>

              <div>
                <span> ID : &nbsp; </span> <span> {props.id} </span>{" "}
              </div>
              <div>
                <span> Nom Utilisateur : &nbsp; </span>{" "}
                <span>
                  {props.email} &nbsp; <b>S</b>{" "}
                </span>
              </div>
              <div>
                <span> Numero Telephone : &nbsp; </span>{" "}
                <span> {props.numPaiement} </span>{" "}
              </div>
              <div>
                <span> Points à retirer : &nbsp; </span>{" "}
                <span
                  style={{
                    backgroundColor: "#ac180431",
                    color: "#ac1804",
                  }}
                >
                  {" "}
                  {props.pointRetrait}{" "}
                </span>{" "}
              </div>
              <div>
                <span> Moyen de paiment : &nbsp; </span>{" "}
                <span> {props.moyenPaiement} </span>{" "}
              </div>
            </div>
          </div>

          <div>
            <div className="row1_details_header">
              <span style={{ color: "black" }}>
                <b> </b>{" "}
              </span>
              {/* <span> {props.partDescription} </span> */}
            </div>

            <div className="row1_details_details">
              <br />

              <span style={{ color: "black" }}>
                {" "}
                <b>Détails</b>
              </span>

              <div>
                <input
                  type="text"
                  placeholder="Entrer la reference de paiement"
                  className="search-bar-hs2"
                  style={{
                    width: "29vw",
                  }}
                  value={refTerm}
                  onChange={(e) => setRefTerm(e.target.value)}
                />
              </div>
              <div>
              <button
                  style={{
                    position: "relative",
                    left: "1px",
                    fontSize: "12px",
                    color: "#4eac04",
                  }}
                  onClick={handleUpdateRetrait}
                  disabled={loading}
                  className="search-button2"
                >
                  {loading ? 'Loading...' : 'Confirmer'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

     
    </div>
  );
};
const Bilan = (props) => {
  return (
    <div className="sect1_bilan">
      <div style={{ borderColor: props.color }}>{props.count}</div>
      <span>{props.title}</span>
    </div>
  );
};
const Sect1 = () => {
  const [nomOperateur, setNomOperateur] = useState([]);
  const [info, setInfo] = useState([]);
  const [displayItem, setDisplayItem] = useState(false);
  const [user, setUser] = useState([]);

  const {
    retraitStatus,
    setRetraitStatus,
    setTotalPartenaires,
          totalPartenaires,
          totalUsagers,
          setTotalUsagers,
  } = useContext(RegContext);

  const [total, setTotal] = useState(0);
  const [itemsPerPage2, setItemsPerPage2] = useState(6);
  const [searchTerm2, setSearchTerm2] = useState("");
  const [currentPage2, setCurrentPage2] = useState(1);
  const [totalPages2, setTotalPages2] = useState(0);
  const [hommesSandwich, setHommesSandwich] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [visionnages, setVisionnages] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [retraits, setRetraits] = useState([]);
  const [countDemandes, setCountDemandes] = useState(0);

  const closeChild = () => {
    setDisplayItem(false);
  };
  useEffect(() => {
    setLoading(true);
    const limit1 = itemsPerPage2;
    const page = currentPage2;
    axios
      .get(
        `${BACKEND_API_URL}/api/retrait?limit=${limit1}&page=${page}&search=${searchTerm2}`
      )
      .then((response) => {
        // console.log(response.data.viewings);
        // setVisionnages(response.data.viewings);
        // setTotalPages2(response.data.totalPages);
        setRetraits(response.data.retraits);
        setCountDemandes(response.data.retraits.length);
        setLoading(false);
        setRetraitStatus(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [ retraitStatus]);

  const handleUser = (info) => {
    setUser(info);
    setDisplayItem(true);
  };

  return (
    <div className="partenaires_sect1 points_sect1">
      <div className="partenaires_sect1_top">
        <div
          className="donnees_sect1_bilan_left"
          style={{
            fontSize: "12px",
          }}
        >
          {" "}
          Gestion points & parrainages{" "}
        </div>
        {/* <div className='donnees_sect1_bilan_right' > Filtrer &nbsp;  <i class="fa-solid fa-sort"></i></div> */}
        {/* 
      <Bilan  title="Orange" count="210" color="#FF7900" />
      <Bilan title="Moov" count="120" color="#0066B3" />
      <Bilan title="Mtn" count="170" color="#FFCA00" />
           */}
        <Bilan title="Retraits en attente" count={countDemandes} />
        <Bilan title="Parrainages" count={0} />
        <Bilan title="Totales partages" count={0} />
        <Bilan title="Partenaires" count={totalPartenaires} />
        <Bilan title="Usagers" count={totalUsagers} />

        {/* {nomOperateur.map(nomOperateur =>(
        <Bilan key={nomOperateur._id} title={nomOperateur.nom} count={nomOperateur.nbre} color={nomOperateur.nom == "Moov" ? "#E2EAED" : nomOperateur.nom == "Orange" ? "#E2EAED": "#E2EAED" } />
      ))} */}
      </div>

      <div className="partenaires_sect1_bottom">
        <div className="partenaires_sect1_bottom_sect_topPoints partenaires_sect1_bottom_sect_topPoints3">
          <span className="blink">({countDemandes}) nouvelles</span>
          <div>Demandes de retrait</div>
          <div className="search-bar-container2">
            <input
              type="text"
              placeholder="Recherche par email"
              className="search-bar-hs2"
              value={searchTerm2}
              onChange={(e) => setSearchTerm2(e.target.value)}
            />
            <button
              onClick=""
              disabled={
                (loading && searchTerm2.length > 0) ||
                (!loading && searchTerm2.length == 0)
                  ? true
                  : false
              }
              className={
                (loading && searchTerm2.length > 0) ||
                (!loading && searchTerm2.length == 0)
                  ? "search-button-disabled2"
                  : "search-button2"
              }
            >
              <i className="fa fa-search"></i>
            </button>
          </div>
        </div>

        <div
          className="partenaires_sect1_bottom_sect_table partenaires_sect1_bottom_sect_table3 "
          //  style={{ overflowY: 'scroll', height: '300px' }}
        >
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Email</th>
                <th>Points</th>
                <th>Numéro de paiment</th>
                <th>Moyen de paiment</th>
                <th> Action </th>
              </tr>
            </thead>
            <tbody>
              {retraits.map((retrait) => (
                <tr key={retrait._id}>
                  <td>{retrait._id}</td>
                  <td>{retrait.email}</td>
                  <td>{retrait.pointRetrait}</td>
                  <td>{retrait.numPaiement}</td>
                  <td>{retrait.moyenPaiement}</td>
                  {/* <td>{retrait.idOperateur.nomOperateur}</td> */}

                  {/* <td>1</td>
              <td>58</td>
              <td>88</td>
              <td>186</td>
              <td>05</td>
              <td>05</td> */}
                  <td>
                    {" "}
                    <i
                      onClick={() => handleUser(retrait)}
                      className="fa-solid fa-tv"
                    ></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {displayItem && (
            <Details
              id={user._id}
              email={user.email}
              pointRetrait={user.pointRetrait}
              numPaiement={user.numPaiement}
              moyenPaiement={user.moyenPaiement}
              //  nom={user.idUsager.nom}
              //  video ={user.idVideo.intitulee}
              //  tel={user.idUsager.tel}
              //  gigaDonnée={user.nbreDonneeCreditee}
              //  datenaisse={user.idUsager.datenaissance}
              //  operateur={user.idOperateur.nomOperateur}

              onClose={closeChild}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Sect1;
