import React, { useEffect, useState } from 'react'
import axios from 'axios'
import contact from "../../images/perm-contact-calendar.svg"
import user from "../../images/user-circle.svg"
const { BACKEND_API_URL } = require("../../../../env");


const MyContext = React.createContext();
const Sect2 = () => {
 
  const [imageSrc, setImageSrc]  = useState("./images/yango_logo.png")

  const getImageUrl = (e)=>{

    setImageSrc(URL.createObjectURL(e.target.files[0]))

  }
  const [nom,setNom]= useState("")
  const [sexe,setSexe]= useState("")
  const [tel,setTel]= useState("")
  const [localisation,setLocalisation]= useState("")
  const [users, setUsers] = useState([]);

  const searchUser = async ()=>{
     axios
    .get(`${BACKEND_API_URL}/api/usager/foundUsager?nom=${nom}&sexe=${sexe}&localisation=${localisation}&tel=${tel}`)
    .then((response) => {
      console.log(response.data);
      setUsers(response.data)
    })
    .catch((error) => {
      console.log(error);
    });
    console.log("ok")
    
  }
  return (
    <div className='user_sect2' >

      <div className='user_sect2_part_top' >

          <div> Profil utilisateur selectionné </div>

      </div>

      <div className='user_sect2_part_bottom' >

        <div className='user_add_form' >

          <div>
        <div className='icon-user'> <img src={user} /></div>

            <div  className='user_form_field' >
              <input id='nom' type="text" placeholder='Nom complet' name="" onChange={(e)=>setNom(e.target.value)}/>
            </div>
            <div  className='user_form_field' >
              <input id='numero' type="text" placeholder='Numero' name="" onChange={(e)=>setTel(e.target.value)} />
            </div>
     
            <div  className='user_form_field' >
              <input id='commune' type="text" placeholder='Commune' name="" onChange={(e)=>setLocalisation(e.target.value)} />
            </div>
          </div>
        </div>

      </div>
      {/* <div className='box-butts'> */}
      <button className='but-drop' onClick={()=>searchUser()}> Valider </button>
      <button className='but-drops'> Supprimer </button>
      {/* </div> */}
      
      <div className='box-filter'>
        <div className='range-age'>
          <div className='filter'>
            <h4>Filtrer</h4>
            <img src={contact} width="22px" />
          </div>
        <label>Age <span>(Entre 18 et 60)</span></label>
        <input type="range" min="10" max="80"/>
        <label>Sexe</label>
        </div>
        <div className='but-sexe'>

        <button value="masculin" onClick={(e)=>setSexe(e.target.value)}>Masculin</button>
        <button value="féminin" onClick={(e)=>setSexe(e.target.value)}>Feminin</button>
        </div>
       
      </div>
    </div>
  )
}

export default Sect2
