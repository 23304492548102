import "./App.css";
import Acceuil from "./components/Acceuil";
import Navbar from "./components/pages/Navbar";
import { BrowserRouter } from "react-router-dom";
import { useState } from "react";
import { RegContext } from "./components/pages/regContext/RegContext";

function App() {
  const [partAlert, setPartAlert] = useState(false);
  const [checkValid, setCheckValid] = useState(true);
  const [checkStatus, setCheckStatus] = useState(false);
  const [prospects, setProspects] = useState([])
  const [retraitStatus, setRetraitStatus] = useState(false)
  const [totalPartenaires, setTotalPartenaires] = useState(0)
  const [totalUsagers, setTotalUsagers] = useState(0)

  return (
    <div className="App">
      <BrowserRouter>
        <RegContext.Provider value={{ partAlert, setPartAlert, 
        checkValid, 
          setCheckValid,
          checkStatus,
          setCheckStatus,
          prospects,
          setProspects,
          retraitStatus,
          setRetraitStatus,
          setTotalPartenaires,
          totalPartenaires,
          totalUsagers,
          setTotalUsagers,
          }}>
          <Acceuil />
        </RegContext.Provider>
      </BrowserRouter>
    </div>
  );
}

export default App;
