import React, { useState, useContext } from "react";
import giga_plus from "./images/giga_plus_logo.png";
import loader from "./images/app_loader.gif";
import axios from "axios";
import { useNavigate } from "react-router-dom";
// import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { RegContext } from "./regContext/RegContext";
import validator from "validator";
const { BACKEND_API_URL } = require("../../env");

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AdminReset = () => {
  const { partAlert } = useContext(RegContext);

  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");
  const [emailPart, setEmailPart] = useState("");
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [partSuccess, setPartSuccess] = useState(true);
  const [successText, setSuccessText] = useState("");
  const [success, setSuccess] = useState(false);

  const handleSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setPartSuccess(false);
  };

  //   const handleError = (event, reason) => {
  //     if (reason === 'clickaway') {
  //       return;
  //     }
  //       setError(false);
  // }

  const handleCheck = async () => {
    setLoading(true);

    if (!validator.isEmail(emailPart)) {
      setError(true);
      setErrorText("Email non conforme!");
      setLoading(false);
      return;
    }

    try {
      const res = await axios.post(
        `${BACKEND_API_URL}/api/sendResetCode`,
        {
          emailPartenaire1: emailPart,
        }
      );
      console.log(res.data);
      setLoading(false);
      setSuccess(true);
      setSuccessText("Un code de vérification a été envoyé à votre email");
      setTimeout(() => {
        navigate("/");
      }, 3000); // add a delay of 5 seconds (5000ms) before navigating to "/"
    } catch (err) {
      if (err.response.status === 409 || err.response.status === 400) {
        setError(true);
        setErrorText(err.response.data.error);
        console.log(err);
        setLoading(false);
      }

      setLoading(false);
    }
  };

  return (
    <div className="login_page">
      <div className="partenaires_reg_page">
        <img className="login_form_main_logo" src={giga_plus} alt="" />

        <span>
          Entrer votre email pour génerer un nouveau mot de pass
        </span>

        <div className="partenaires_reg_page_inputs">
          {/* <div  className='login_form_field' >
            <label for='nom' >Nom Partenaire</label>
            <input style={{paddingLeft:"0.5rem"}} onChange={(e)=>setCode(e.target.value)} id='nom' type="text" placeholder='Entrer votre code: #GP-XXXX' name=""/>
            </div> */}
          <div className="login_form_field">
            {/* <label for='nom' >Nom Partenaire</label> */}
            <input
              onChange={(e) => setEmailPart(e.target.value)}
              id="nom"
              type="email"
              placeholder="Entrer votre email"
              name=""
            />
          </div>

          {/* <div  className='login_form_field' >
            <input id='password' type="password" placeholder='Entrer votre mot de pass' name=""/>
            </div> */}

          <a
            style={{
              height: "7vh",
              // backgroundColor:"white",
              position: "relative",
              left: "2vw",
            }}
            href="#"
          >
            <button
              onClick={handleCheck}
              style={{
                position: "relative",
                bottom: "2vh",
                left: "0vw",
                top: "0vh",
              }}
              className="login_page_main_button"
            >
              {" "}
              Changer{" "}
            </button>{" "}
          </a>

          {/* <div className='login_form_question_div' > -&nbsp; Questions? &nbsp;- </div> */}
        </div>

        {partAlert && (
          <Stack spacing={2} sx={{ width: "70%" }}>
            <Snackbar
              open={partSuccess}
              autoHideDuration={6000}
              onClose={handleSuccess}
            >
              <Alert
                onClose={handleSuccess}
                severity="success"
                sx={{ width: "100%" }}
              >
                Opération réussie! Votre inscription sera validé et vous allez
                recevoir un email.
              </Alert>
            </Snackbar>
          </Stack>
        )}

        {error && (
          <Alert style={{ marginLeft: "3.5rem" }} severity="error">
            {" "}
            {errorText}{" "}
          </Alert>
        )}
        {success && (
          <Alert style={{ marginLeft: "3.5rem" }} severity="success">
            {" "}
            {successText}{" "}
          </Alert>
        )}

        {loading && (
          <img
            style={{ width: "5%" }}
            className="reg_loader"
            src={loader}
            alt="loading"
          />
        )}
      </div>
    </div>
  );
};

export default AdminReset;
