import React, { useState, useContext, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import validator from "validator";
import axios from "axios";
import { RegContext } from "../../regContext/RegContext";
const { BACKEND_API_URL } = require("../../../../env");

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Sect2 = () => {
  const [nomOperateur, setNomOperateur] = useState([]);
  const [info, setInfo] = useState([]);
  const [displayItem, setDisplayItem] = useState(false);
  const [user, setUser] = useState([]);

  const [total, setTotal] = useState(0);
  const [itemsPerPage2, setItemsPerPage2] = useState(6);
  const [searchTerm2, setSearchTerm2] = useState("");
  const [currentPage2, setCurrentPage2] = useState(1);
  const [totalPages2, setTotalPages2] = useState(0);
  const [hommesSandwich, setHommesSandwich] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [visionnages, setVisionnages] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [retraits, setRetraits] = useState([]);

  const closeChild = () => {
    setDisplayItem(false);
  };
  useEffect(() => {
    setLoading(true);
    const limit1 = itemsPerPage2;
    const page = currentPage2;
    axios
      .get(
        `${BACKEND_API_URL}/api/retrait?limit=${limit1}&page=${page}&search=${searchTerm2}`
      )
      .then((response) => {
        // console.log(response.data.viewings);
        // setVisionnages(response.data.viewings);
        // setTotalPages2(response.data.totalPages);
        // setRetraits(response.data.retraits);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleUser = (info) => {
    setUser(info);
    setDisplayItem(true);
  };

  return (
    <div className="partenaires_sect2 points_sect2">
      <div className="gestions_sect2_top">
        <div className="partenaires_sect1_bottom_sect_topPoints partenaires_sect1_bottom_sect_topPoints3 partenaires_sect1_bottom_sect_topPoints4">
          <div>Recap partages</div>
          <div className="search-bar-container2">
            <input
              type="text"
              placeholder="Rechercher un usager par son email"
              className="search-bar-hs2"
              value={searchTerm2}
              onChange={(e) => setSearchTerm2(e.target.value)}
            />
            <button
              onClick=""
              disabled={
                (loading && searchTerm2.length > 0) ||
                (!loading && searchTerm2.length == 0)
                  ? true
                  : false
              }
              className={
                (loading && searchTerm2.length > 0) ||
                (!loading && searchTerm2.length == 0)
                  ? "search-button-disabled2"
                  : "search-button2"
              }
            >
              <i className="fa fa-search"></i>
            </button>
          </div>
        </div>

        <div
          className="partenaires_sect1_bottom_sect_table partenaires_sect1_bottom_sect_table3 "
          //  style={{ overflowY: 'scroll', height: '300px' }}
        >
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Usager parrainé</th>
                <th>Date d'enregistrement</th>
                <th>Nom parrain</th>
                {/* <th>Moyen de paiment</th> */}
                <th> Action </th>
              </tr>
            </thead>
            <tbody>
              {retraits.map((retrait) => (
                <tr key={retrait._id}>
                  <td>{retrait._id}</td>
                  <td>{retrait.email}</td>
                  <td>{retrait.pointRetrait}</td>
                  <td>{retrait.numPaiement}</td>
                  {/* <td>{retrait.moyenPaiement}</td> */}
                  {/* <td>{retrait.idOperateur.nomOperateur}</td> */}

                  {/* <td>1</td>
              <td>58</td>
              <td>88</td>
              <td>186</td>
              <td>05</td>
              <td>05</td> */}
                  <td>
                    {" "}
                    <i
                      onClick={() => handleUser(retrait)}
                      className="fa-solid fa-tv"
                    ></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {/* {displayItem && (
            <Details
              id={user._id}
              nom={user.idUsager.nom}
              video={user.idVideo.intitulee}
              tel={user.idUsager.tel}
              gigaDonnée={user.nbreDonneeCreditee}
              datenaisse={user.idUsager.datenaissance}
              operateur={user.idOperateur.nomOperateur}
              onClose={closeChild}
            />
          )} */}
        </div>
      </div>

      <div className="gestions_sect2_bottom">
        <div className="partenaires_sect1_bottom_sect_topPoints partenaires_sect1_bottom_sect_topPoints3 partenaires_sect1_bottom_sect_topPoints4">
          <div>Recap parrainages</div>
          <div className="search-bar-container2">
            <input
              type="text"
              placeholder="Rechercher un usager parrainé"
              className="search-bar-hs2"
              value={searchTerm2}
              onChange={(e) => setSearchTerm2(e.target.value)}
            />
            <button
              onClick=""
              disabled={
                (loading && searchTerm2.length > 0) ||
                (!loading && searchTerm2.length == 0)
                  ? true
                  : false
              }
              className={
                (loading && searchTerm2.length > 0) ||
                (!loading && searchTerm2.length == 0)
                  ? "search-button-disabled2"
                  : "search-button2"
              }
            >
              <i className="fa fa-search"></i>
            </button>
          </div>
        </div>

        <div
          className="partenaires_sect1_bottom_sect_table partenaires_sect1_bottom_sect_table3 "
          //  style={{ overflowY: 'scroll', height: '300px' }}
        >
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Usager parrainé</th>
                <th>Date d'enregistrement</th>
                <th>Nom parrain</th>
                {/* <th>Moyen de paiment</th> */}
                <th> Action </th>
              </tr>
            </thead>
            <tbody>
              {retraits.map((retrait) => (
                <tr key={retrait._id}>
                  <td>{retrait._id}</td>
                  <td>{retrait.email}</td>
                  <td>{retrait.pointRetrait}</td>
                  <td>{retrait.numPaiement}</td>
                  {/* <td>{retrait.moyenPaiement}</td> */}
                  {/* <td>{retrait.idOperateur.nomOperateur}</td> */}

                  {/* <td>1</td>
              <td>58</td>
              <td>88</td>
              <td>186</td>
              <td>05</td>
              <td>05</td> */}
                  <td>
                    {" "}
                    <i
                      onClick={() => handleUser(retrait)}
                      className="fa-solid fa-tv"
                    ></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {/* {displayItem && (
            <Details
              id={user._id}
              nom={user.idUsager.nom}
              video={user.idVideo.intitulee}
              tel={user.idUsager.tel}
              gigaDonnée={user.nbreDonneeCreditee}
              datenaisse={user.idUsager.datenaissance}
              operateur={user.idOperateur.nomOperateur}
              onClose={closeChild}
            />
          )} */}
        </div>
      </div>
    </div>
  );
};

export default Sect2;
