import React, { useState, useContext } from "react";
import giga_plus from "./images/selected_logo022.png";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { RegContext } from "./regContext/RegContext";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

const { BACKEND_API_URL } = require("../../env");

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const theme = createTheme({
  palette: {
    neutral: {
      main: "#4EAC04",
      contrastText: "#fff",
    },
  },
});

const PartRegForm = () => {
  const { setPartAlert } = useContext(RegContext);

  // nomPartenaire:{type:String , require:true},
  // activitePartenaire:{type:String , require:true},
  // emailPartenaire1:{type:String , require:true},
  // emailPartenaire2:{type:String , require:true},
  // telPartenaire:{type:String , require:true},
  // fixePartenaire:{type:String , require:true},
  // villePartenaire:{type:String , require:true},
  // communePartenaire:{type:String , require:true},
  // descriptionPartenaire:{type:String , require:true},
  // logoPartenaire:{type:String , require:true},
  // logo : {
  //     data: Buffer,
  //     contentType: "image/png"
  // }

  const [nomPartenaire, setNomPartenaire] = useState("");
  const [activitePartenaire, setActivitePartenaire] = useState("");
  const [emailPartenaire1, setEmailPartenaire1] = useState("");
  const [emailPartenaire2, setEmailPartenaire2] = useState("");
  const [telPartenaire, setTelPartenaire] = useState("");
  const [fixePartenaire, setFixePartenaire] = useState("");
  const [villePartenaire, setVillePartenaire] = useState("");
  const [communePartenaire, setCommunePartenaire] = useState("");
  const [descriptionPartenaire, setDescriptionPartenaire] = useState("");
  const [logo, setLogo] = useState("");
  const navigate = useNavigate();
  const [partError, setPartError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [error, setError] = React.useState("");

  const [imageSrc, setImageSrc] = useState("./images/yango_logo.png");

  const getImageUrl = (e) => {


    const file = e.target.files[0];
    const reader = new FileReader();
    
    reader.onload = function(e) {
      const img = new Image();
      
      img.onload = function() {
        if (img.width > 256 || img.height > 256) {
          setError("L'Image doit avoir une taille maximale de 256x256 pixels");
          e.target.value = null; // Reset file input
          setImageSrc("");
          setLogo("");
        } else {
          setError(""); // Clear error message
        }
      };
      
      img.src = e.target.result;
    };
    
    reader.readAsDataURL(file);


    setImageSrc(URL.createObjectURL(e.target.files[0]));
    setLogo(e.target.files[0]);
    console.log(e.target.files[0].name);
  };


  const handleError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
      setPartError(false);
}

  const handleRegForm = async () => {


    if(logo === "") { 
      setErrorText("Veuillez choisir un logo");
      setPartError(true);
      return;
    }

    let fd = new FormData();
    fd.append("nomPartenaire", nomPartenaire);
    fd.append("activitePartenaire", activitePartenaire);
    fd.append("emailPartenaire1", emailPartenaire1);
    fd.append("emailPartenaire2", emailPartenaire2);
    fd.append("telPartenaire", telPartenaire);
    fd.append("fixePartenaire", fixePartenaire);
    fd.append("villePartenaire", villePartenaire);
    fd.append("communePartenaire", communePartenaire);
    fd.append("descriptionPartenaire", descriptionPartenaire);
    fd.append("logo", logo);
    // fd.append("mdp", "$2a$12$q4za7sngB8h.d4GRdJRP0eMVfugcRzkVjRLVd9z5EMkIptvQH6veS");

    try {

      const res = await axios.post(`${BACKEND_API_URL}api/partenaireRegister`, fd);
      console.log(res.data);
      // setLoading(false);
    setPartAlert(true);
      navigate("/partenaires/reg");

    }
    catch(err) {

      console.log(err);
      setErrorText(err.response.data.error);
      setPartError(true);

    }

  };


  // function getImageUrl(event) {
  //   const file = event.target.files[0];
  //   const reader = new FileReader();
    
  //   reader.onload = function(e) {
  //     const img = new Image();
      
  //     img.onload = function() {
  //       if (img.width !== 256 || img.height !== 256) {
  //         setError("Image must be 256x256 pixels");
  //       } else {
  //         // TODO: Handle valid image here
  //       }
  //     };
      
  //     img.src = e.target.result;
  //   };
    
  //   reader.readAsDataURL(file);
  // }

  return (
    <div className="form_page">
      <div>
        <span>Formulaire de préinscription Partenaire</span>
      </div>

      <div>
        <div className="regform_form">
          <img
            style={{ width: "10%" }}
            className="login_form_main_logo"
            src={giga_plus}
            alt=""
          />

          <div className="regform_form_fields_div">
            <div>
              <TextField
                style={{ width: "16vw" }}
                id="standard-basic"
                size="small"
                label="Nom Entreprise"
                variant="standard"
                onChange={(e) => setNomPartenaire(e.target.value)}
              />
              <TextField
                style={{ width: "16vw" }}
                id="standard-basic"
                size="small"
                label="Secteur d'activité"
                variant="standard"
                onChange={(e) => setActivitePartenaire(e.target.value)}
              />
            </div>

            <div>
              <TextField
                style={{ width: "16vw" }}
                id="standard-basic"
                size="small"
                label="Email 1"
                variant="standard"
                onChange={(e) => setEmailPartenaire1(e.target.value)}
              />
              <TextField
                style={{ width: "16vw" }}
                id="standard-basic"
                size="small"
                label="Email 2"
                variant="standard"
                onChange={(e) => setEmailPartenaire2(e.target.value)}
              />
            </div>

            <div>
              <TextField
                style={{ width: "16vw" }}
                id="standard-basic"
                size="small"
                label="Téléphone"
                variant="standard"
                onChange={(e) => setTelPartenaire(e.target.value)}
              />
              <TextField
                style={{ width: "16vw" }}
                id="standard-basic"
                size="small"
                label="Fixe"
                variant="standard"
                onChange={(e) => setFixePartenaire(e.target.value)}
              />
            </div>

            <div>
              <TextField
                style={{ width: "16vw" }}
                id="standard-basic"
                size="small"
                label="Ville"
                variant="standard"
                onChange={(e) => setVillePartenaire(e.target.value)}
              />
              <TextField
                style={{ width: "16vw" }}
                id="standard-basic"
                size="small"
                label="Commune"
                variant="standard"
                onChange={(e) => setCommunePartenaire(e.target.value)}
              />
            </div>

            <div className="part_logo_preview_div">
              <label className="part_img_label" for="file">
                Choisir logo
              </label>
              <input
                onChange={getImageUrl}
                type="file"
                name="file"
                id="file"
                accept="image/png, image/jpeg"
                hidden
                required
              />
              <div className="part_logo_preview">
                <img
                  id="curr_logo_preview_src"
                  className="curr_logo_partenaire"
                  src={imageSrc}
                  alt=""
                />
              </div>
            </div>
            {error && <p style={{ color: "red",fontSize:"1rem", marginLeft:"4vw" }}>{error}</p>}
            <div style={{ marginTop: "2vh" }}>
              <TextField
                id="outlined-multiline-static"
                label="Description"
                multiline
                rows={4}
                defaultValue=""
                style={{ width: "34vw" }}
                onChange={(e) => setDescriptionPartenaire(e.target.value)}
              />
            </div>
          </div>

          <ThemeProvider theme={theme}>
            <Button onClick={handleRegForm} color="neutral" variant="contained">
              Envoyer
            </Button>
          </ThemeProvider>

          <Stack spacing={2} sx={{ width: "70%" }}>
            <Snackbar
              open={partError}
              autoHideDuration={3000}
              onClose={handleError}
            >
              <Alert
                onClose={handleError}
                severity="error"
                sx={{ width: "100%" }}
              >
               {errorText}
              </Alert>
            </Snackbar>
          </Stack>
        </div>
      </div>
    </div>
  );
};

export default PartRegForm;
