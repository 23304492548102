import React, { useState, useEffect } from "react";
import "../../styles/Visionnages.css";
import video from "./images/video-library.svg";
import Sect1 from "./sub/Visionnages/Sect1";
import axios from "axios";
import Alert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import info from "./images/information-slab-circle.svg";
import { ScaleLoader } from "react-spinners";
import { Cookies, useCookies } from "react-cookie";

const { BACKEND_API_URL } = require("../../env");
const Bilan = (props) => {
  return (
    <div className="sect1_bilan">
      <div>{props.count}</div>
      <span>{props.title}</span>
    </div>
  );
};
const Bilan1 = (props) => {
  return (
    <div className="sect1_bilan sect1_bilanU1  ">
      <div>{props.count}</div>
      <span>{props.title}</span>
    </div>
  );
};

const Details2 = (props) => {
  // const [displayItem, setDisplayItem] = useState(true)

  return (
    <div className="sect3_elem_details">
      <div>
        {/* <FontAwesomeIcon  style={{color:"black"}}  icon="fa-solid fa-circle-xmark" /> */}
        <div onClick={props.onClose} className="elem_details_closure">
          {" "}
          <i className="fa-solid fa-xmark"></i>{" "}
        </div>

        <div className="part_details_div">
          <div>
            <div className="row1_details_header">
              <span style={{ color: "black" }}>
                <b> Détails Visionnage </b>{" "}
              </span>
              {/* <span> {props.partDescription} </span> */}
            </div>

            <div className="row1_details_details">
              <br />

              <span style={{ color: "black" }}>
                {" "}
                <b>Détails</b>
              </span>

              <div>
                <span> ID : &nbsp; </span> <span> {props.idVis} </span>{" "}
              </div>
              <div>
                <span> Durée Vidéo : &nbsp; </span>{" "}
                <span>
                  {" "}
                  {props.dureeVidVis} &nbsp; <b>S</b>{" "}
                </span>
              </div>
              <div>
                <span> Points offerts : &nbsp; </span>{" "}
                <span> {props.gigaVis}</span>{" "}
              </div>

              <div>
                <span> Nom partenaire : &nbsp; </span>{" "}
                <span> {props.idPartVis} </span>{" "}
              </div>
              <div>
                <span> Nom usager : &nbsp; </span>{" "}
                <span> {props.idUsagerVis} </span>{" "}
              </div>
            </div>
          </div>

          <div>
            <div className="row1_details_header">
              <span style={{ color: "black" }}>
                <b> Détails Visionnage </b>{" "}
              </span>
              {/* <span> {props.partDescription} </span> */}
            </div>

            <div className="row1_details_details">
              <br />
              <span style={{ color: "black" }}>
                {" "}
                <b>Détails</b>
              </span>

              <div>
                <span> ID_vidéo : &nbsp; </span> <span> {props.idVidVis} </span>{" "}
              </div>
              <div>
                <span> Date Visionnage : &nbsp; </span>{" "}
                <span>
                  {" "}
                  {new Date(props.dateVis).toLocaleString("fr-CI", {
                    day: "numeric",
                    month: "numeric",
                    year: "2-digit",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                    timeZone: "Africa/Abidjan",
                  })}{" "}
                </span>{" "}
              </div>
              {/* <div>
                <span> Description Activite : &nbsp; </span>{" "}
                <span> {props.partDescription} </span>{" "}
              </div> */}

              <div className="row_status_div"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function Visionnages() {
  const [displayItem, setDisplayItem] = useState(false);
  const [cookies2] = useCookies(["giga_partenaire"]);
  const [displayItem2, setDisplayItem2] = useState(false);
  const [currHommeSandwich, setCurrHommeSandwich] = useState({});

  const closeChild2 = () => {
    setDisplayItem2(false);
  };

  const closeChild = () => {
    setDisplayItem(false);
  };

  const AddVideo = (props) => {
    // const [displayItem, setDisplayItem] = useState(true)
    const [partenaires, setPartenaires] = useState([]);
    const [packVideo, setPackVideo] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [video, setVideo] = useState(null);
    const [choosePart, setChoosePart] = useState("");
    const [chooseParts, setChooseParts] = useState("");
    const [duree, setDuree] = useState("");
    const [nbDatas, setNbDatas] = useState("");
    const [intitule, setIntitule] = useState("");
    const [show, setShow] = useState(false);
    const [prixCampagneVideo, setPrixCampagneVideo] = useState(0);
    useEffect(() => {
      axios
        .get(`${BACKEND_API_URL}/api/PackVideo/getPackVideo`)
        .then((res) => {
          // setPartenaire(res.data);
          setPackVideo(res.data);
        })
        .catch((e) => console.log(e));
      axios
        .get(`${BACKEND_API_URL}/api/partenaires/createVideo`, {
          params: {
            search: searchTerm,
          },
        })
        .then((res) => {
          // setPartenaire(res.data);
          console.log(res.data);
          // const filteredPartenaires = res.data.filter(
          //   (partenaire) => partenaire.valide === true
          // );
          setPartenaires(res.data);
        })
        .catch((e) => console.log(e));
      axios
        .get(`${BACKEND_API_URL}/api/getTotal`)
        .then((res) => {
          // setPartenaire(res.data);
          setTotalVideo(res.data);
        })
        .catch((e) => console.log(e));

    }, []);

    const addVideo = () => {
      // event.preventDefault();
      const formData = new FormData();
      formData.append("duree", duree);
      formData.append("idPartenaire",partenaireId );
      formData.append("idPackVideo", chooseParts);
      formData.append("video", video);
      // formData.append("nbDatas", nbDatas);
      formData.append("intitule", intitule);
      // formData.append("prixCampagneVideo",prixCampagneVideo)
      setLoading(true);
      axios
        .post(`${BACKEND_API_URL}/api/video/addVideoByPartenaire`, formData)
        .then((res) => {
          console.log(res);
          setLoading(false);
          setShow(true);
          setTimeout(() => {
            setShow(false);
          }, 3000);
          window.location.reload();
        })
        .catch((e) => console.log(e));
    };
    console.log(prixCampagneVideo);

    const top100Films = [
      { nomPart: "Djamo", idPart: 1994, year: 1994 },
      { nomPart: "The Shawshank Redemption", idPart: 1994 },
      // { label: "The Godfather", year: 1972 },
    ];

    return (
      <div className="sect3_elem_detail">
        <div>
          {/* <FontAwesomeIcon  style={{color:"black"}}  icon="fa-solid fa-circle-xmark" /> */}
          <div onClick={props.onClose} className="elem_details_closure">
            {" "}
            <i className="fa-solid fa-xmark"></i>{" "}
          </div>

          <div className="part_details_divs" style={{ marginTop: -40 }}>
            <div>
              <div className="row1_details_header">
                {show && (
                  <Alert
                    severity="success"
                    style={{ position: "relative", left: 230, bottom: 150 }}
                  >
                    Ajout de video effectué
                  </Alert>
                )}

                <span style={{ color: "black" }}>
                  <b> Ajout De Video </b>{" "}
                </span>
                {/* <span> {props.partDescription} </span> */}
              </div>

              <div className="row1_details_detail">
                <br />
                <div>
                  <span> Durée Video (en seconde) &nbsp; </span>
                  <input
                    className="addVideo_input"
                    placeholder="en seconde..."
                    type="text"
                    style={{ marginLeft: "160px" }}
                    name="video"
                    onChange={(e) => setDuree(e.target.value)}
                  />
                </div>

                {/* <div>
                  <span> Datas(valeur) &nbsp; </span>
                  <input
                    className="addVideo_input"
                    placeholder="en MB..."
                    type="text"
                    style={{ marginLeft: "160px" }}
                    name="video"
                    onChange={(e) => setNbDatas(e.target.value)}
                  />
                </div> */}

                <div>
                  <span> Intitule de la video &nbsp; </span>
                  <input
                    className="addVideo_input"
                    placeholder="..."
                    type="text"
                    style={{ marginLeft: "160px" }}
                    name="video"
                    onChange={(e) => setIntitule(e.target.value)}
                  />
                </div>

                {/* <div>
                  <span> Montant Video &nbsp; </span>
                  <input
                    className="addVideo_input"
                    placeholder="..."
                    type="text"
                    style={{ marginLeft: "160px" }}
                    name="video"
                    onChange={(e) => setPrixCampagneVideo(e.target.value)}
                  />
                </div> */}
                <div>
                  <span> Video à Ajouté : &nbsp; </span>{" "}
                  <input
                    className="video_file"
                    type="file"
                    style={{ marginLeft: "160px" }}
                    name="duree"
                    onChange={(e) => setVideo(e.target.files[0])}
                  />
                </div>
                {/* <div>
                  <span> Partenaire : &nbsp; </span>{" "} */}
                  {/* <select style={{marginRight:'100px'}} name="partenaire" onChange={(e)=>setChoosePart(e.target.value)}>
                {partenaire.map(partenaire => (
                  <option key={partenaire._id} value={partenaire._id} >{partenaire.nomPartenaire} </option>
                  ))}
                </select> */}
                  {/* <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={partenaires}
                    size="small"
                    sx={{ width: 250 }}
                    getOptionLabel={(option) => option.nomPartenaire}
                    renderInput={(params) => (
                      <TextField {...params} label="Partenaire" />
                    )}
                    onChange={(e, value) => setChoosePart(value._id)}
                  />
                </div> */}
                <div style={{ marginTop: "10px" }}>
                  <span> Pack Video &nbsp; </span>{" "}
                  {/* <select style={{marginRight:'100px'}} name="partenaire" onChange={(e)=>setChoosePart(e.target.value)}>
                {packVideo.map(partenaire => (
                  <option key={partenaire._id} value={partenaire._id} >{partenaire.nom} </option>
                  ))}
                </select>  */}
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={packVideo}
                    size="small"
                    sx={{ width: 250 }}
                    getOptionLabel={(option) => option.nom}
                    renderInput={(params) => (
                      <TextField {...params} label="Pack Video" />
                    )}
                    onChange={(e, value) => setChooseParts(value._id)}
                    style={{ marginLeft: "150px" }}
                  />
                  <span>
                    <img src={info} onClick={(e) => setDisplayItem(true)} />
                  </span>
                  <span style={{ fontSize: "12px", color: "black" }}>
                    Plus d'Info
                  </span>
                </div>
                <button
                  style={{
                    marginTop: "20px",
                    alignSelf: "center",
                    width: "110px",
                    height: "30px",
                    borderRadius: 6,
                    border: "none",
                    fontWeight: "bold",
                    backgroundColor: "#29363F",
                    color: "#ffff",
                    cursor: "pointer",
                  }}
                  onClick={() => addVideo()}
                >
                  AJOUTER
                </button>
                {loading && (
                  <ScaleLoader
                    style={{
                      position: "absolute",
                      top: "50vh",
                      left: "48%",
                    }}
                    color="#4EAC04"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const [totalVideo, setTotalVideo] = useState(0);
  const [totalVision, setTotalVision] = useState(0);
  useEffect(() => {
    axios
      .get(`${BACKEND_API_URL}/api/video/getTotal`)
      .then((res) => {
        console.log(res.data);
        setTotalVideo(res.data);
      })
      .catch((e) => console.log(e));
    axios
      .get(`${BACKEND_API_URL}/api/visionnage/getTotal`)
      .then((res) => {
        console.log(res.data);
        setTotalVision(res.data);
      })
      .catch((e) => console.log(e));
  }, []);

  // code from sect page

  // const [displayItem, setDisplayItem] = useState(false);

  const [visionnages, setVisionnages] = useState([]);
  const [currVisionnage, setCurrVisionnage] = useState({});
  const [recherche, setRecherche] = useState("");
  const [loading, setLoading] = useState(true);
  // const closeChild = () => {
  //   setDisplayItem(false);
  // };

  const [itemsPerPage2, setItemsPerPage2] = useState(6);
  const [searchTerm2, setSearchTerm2] = useState("");
  const [currentPage2, setCurrentPage2] = useState(1);
  const [totalPages2, setTotalPages2] = useState(0);
  const [hommesSandwich, setHommesSandwich] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  // let currentItems2 = hommesSandwich;
  const [nombreVideos, setNombreVideos] = useState(0);
  const [nombreVisionnage, setNombreVisionnage] = useState(0);
  // const limit1 = 6;

  const partenaireId = cookies2.giga_partenaire.idPartenaire;

  const fetchVisionnages2 = (searchTerm, page) => {
    setLoading(true);
  
    axios
      .get(`${BACKEND_API_URL}/api/visionnages/partenaire/${partenaireId}`, {
        params: {
          limit: itemsPerPage2,
          page: page,
          search: searchTerm
        }
      })
      .then((response) => {
        setVisionnages(response.data.viewings);
        setTotalPages2(response.data.totalPages);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  
  const searchHandler2 = (e) => {
    fetchVisionnages2(searchTerm2, 1); // Call fetchVisionnages2 with the search term and page 1
  };

  const paginate2 = (pageNumber) => {
    setCurrentPage2(pageNumber);
    setLoading(true);

    axios
      .get(`${BACKEND_API_URL}/api/visionnages/partenaire/${partenaireId}`, {
        params: { 
          limit:itemsPerPage2,
           page:currentPage2,
            search:searchTerm2 },
      })
      .then((response) => {
        setVisionnages(response.data.viewings);
        setTotalPages2(response.data.totalPages);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };



  const handleVisionnage = (visionnage) => {
    setCurrHommeSandwich(visionnage);
    setDisplayItem2(true);
    // console.log(visionnage);
    // setCurrVisionnage(visionnage);
    // setDisplayItem(true);
  };


  useEffect(() => {
    const partenaireId = cookies2.giga_partenaire.idPartenaire;
    const limit = 8;
    const page = 1;
    const search = "";
  
    Promise.all([
      axios.get(
        `${BACKEND_API_URL}/api/visionnages/partenaire/${partenaireId}`,
        {
          params: { 
            limit: itemsPerPage2,
            page: currentPage2,
            search: searchTerm2 
          },
        }
      ),
      axios.get(
        `${BACKEND_API_URL}/api/video/getVideosByPartenaire2/${partenaireId}`
      ),
    ])
      .then(([dataVisionnage, dataVideos]) => {
        const videos = dataVideos.data.videos;

        
        console.log("videos: ", dataVideos.data);

        setNombreVideos(dataVideos.data.countVideos);
        setNombreVisionnage(dataVisionnage.data.countVisionnages);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  
    fetchVisionnages2(searchTerm2, currentPage2);
  }, []);

  return (
    <>
      <div className="body-gene">
        <div className="view-vision">
          <div className="information-vision">
            <Bilan title="Nombre total de video" count={nombreVideos} />
            <Bilan1 title="Nombre total de vues " count={nombreVisionnage} />
          </div>
          <div className="list-vision">
            <Sect1 />
          </div>
        </div>

        <div className="view-form">
          <div className="form">
            <div className="log-video">
              <span>Ajouter une video</span>
              {/* <img
                src={video}
                width="120px"
                style={{ cursor: "pointer" }}
                onClick={(e) => setDisplayItem(true)}
              /> */}
            </div>

            <button
              className="log-videoButton"
              onClick={(e) => setDisplayItem(true)}
            >
              <i class="fa-regular fa-circle-play"></i>
            </button>
            <div className="part-choose"></div>
            {/* <button className='but-add' style={{cursor:"pointer"}} onClick={(e)=>setDisplayItem(true)}>Ajouter</button>
            <span className='box-video'>
              <img src={video} width="60px"/>
            </span> */}
            {displayItem && <AddVideo onClose={closeChild} />}
          </div>

          <div className="visionnagesonform">
            <div className="partenaires_sect1_bottom partenaires_sect1_bottom2 ">
              <div
                className="partenaires_sect1_bottom_sect_top"
                style={{ height: 50 }}
              >
                <div>Recap visionnages</div>

                <div className="search-bar-container2">
                  <input
                    type="text"
                    placeholder="..................."
                    className="search-bar-hs2"
                    value={searchTerm2}
                    onChange={(e) => setSearchTerm2(e.target.value)}
                    disabled={true}
                  />
                  <button
                    onClick={searchHandler2}
                    disabled={
                      (loading && searchTerm2.length > 0) ||
                      (!loading && searchTerm2.length == 0)
                        ? true
                        : false
                    }
                    className={
                      (loading && searchTerm2.length > 0) ||
                      (!loading && searchTerm2.length == 0)
                        ? "search-button-disabled2"
                        : "search-button2"
                    }
                  >
                    <i className="fa fa-search"></i>
                  </button>
                </div>

                {/* <div></div> */}
              </div>

              <div
                className="partenaires_sect1_bottom_sect_table partenaires_sect1_bottom_sect_table2"
                style={{
                  // overflowY: "scroll",
                  height: "350px",
                  width: "100%",
                }}
              >
                <table
                  style={{
                    width: "70%",
                  }}
                >
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Nom usager</th>
                      <th>Points offerts</th>
                      <th>Date visionnage</th>
                      <th>Intitule video</th>
                      <th> Action </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading && (
                      <ScaleLoader
                        style={{
                          position: "absolute",
                          top: "20vh",
                          left: "48%",
                        }}
                        color="#4EAC04"
                      />
                    )}
                    {visionnages.map((visionnage) => (
                      <tr key={visionnage._id}>
                        <td>{visionnage._id}</td>

                        <td>{visionnage.usager?.nom ?? "Null"}</td>
                        <td>{visionnage.pointDonnee}</td>
                        <td>
                          {
                            new Date(
                              visionnage.dateVisionnage
                            ).toLocaleDateString("fr-FR", {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                              // hour: '2-digit',
                              // minute: '2-digit',
                              // second: '2-digit',
                              hour12: false,
                            })
                            // (visionnage.dateVisionnage).
                          }
                        </td>
                        <td>{visionnage.video?.intitulee}</td>

                        <td>
                          <i
                            onClick={() => handleVisionnage(visionnage)}
                            className="fa-solid fa-tv"
                          ></i>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <div className="pagination-hs">
                  <button
                    className="pagination-button"
                    onClick={() => paginate2(currentPage2 - 1)}
                    disabled={currentPage2 === 1}
                  >
                    <i className="fa fa-angle-left"></i>
                  </button>
                  <span className="current-page">
                    {currentPage2}/{totalPages2}
                  </span>
                  <button
                    className="pagination-button"
                    onClick={() => paginate2(currentPage2 + 1)}
                    disabled={currentPage2 === totalPages2}
                  >
                    <i className="fa fa-angle-right"></i>
                  </button>
                </div>

                {displayItem2 && (
                  // idHs={currHommeSandwich._id}
                  <Details2
                    idVis={currHommeSandwich._id}
                    idVidVis={currHommeSandwich.idVideo}
                    idUsagerVis={currHommeSandwich.usager?.nom ?? "Null"}
                    // idVidVis={currHommeSandwich.idVideo}
                    gigaVis={currHommeSandwich.pointDonnee}
                    opeVis={currHommeSandwich.OperateurDonneur}
                    idPartVis={
                      currHommeSandwich.partenaire?.nomPartenaire ?? "Null"
                    }
                    dureeVidVis={currHommeSandwich.duréeVideo}
                    dateVis={currHommeSandwich.dateVisionnage}
                    onClose={closeChild2}
                  />
                )}

                {/* {displayItem && (
            <Details
              idVis={currVisionnage._id}
              idVidVis={currVisionnage.idVis}
              intitulee={currVisionnage.video.intitulee}
              nomUsager={currVisionnage.usager?.nom ?? "NULL"}
              // idVidVis={currVisionnage.idVideo}
              duree={currVisionnage.video.duree}
              nbDatas={currVisionnage.pointDonnee}
              packVideo={currVisionnage.video.packVideoCopy.nom}
              gigaRestant={currVisionnage.gigaRestant}
              nomPartenaire={currVisionnage.partenaire?.nomPartenaire ?? "NULL"}
              dateajout={currVisionnage.dateVisionnage}
              statut={currVisionnage.statutVideo ? "Activer" : "Desactiver"}
              prixCampagneVideo={currVisionnage.prixCampagneVideo}
              onClose={closeChild}
            />
          )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
