import React, { useState, useEffect } from "react";
import Popup from "reactjs-popup";
import axios from "axios";
import { ScaleLoader } from "react-spinners";
import Tooltip from "@mui/material/Tooltip";
import { Cookies, useCookies } from "react-cookie";
import Alert from "@mui/material/Alert";
// import 'reactjs-popup/dist/index.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";



const { BACKEND_API_URL } = require("../../../../env");
const Details = (props) => {
  // const [displayItem, setDisplayItem] = useState(true)

  return (
    <div className="sect3_elem_details">
      <div>
        {/* <FontAwesomeIcon  style={{color:"black"}}  icon="fa-solid fa-circle-xmark" /> */}
        <div onClick={props.onClose} className="elem_details_closure">
          {" "}
          <i className="fa-solid fa-xmark"></i>{" "}
        </div>

        <div className="part_details_div">
          <div>
            <div className="row1_details_header">
              <span style={{ color: "black" }}>
                <b> Détails Visionnage </b>{" "}
              </span>
              {/* <span> {props.partDescription} </span> */}
            </div>

            <div className="row1_details_details">
              <br />

              <span style={{ color: "black" }}>
                {" "}
                <b>Détails</b>
              </span>

              <div>
                <span> ID : &nbsp; </span> <span> {props.idVis} </span>{" "}
              </div>
              <div>
                <span> Durée Vidéo : &nbsp; </span>{" "}
                <span>
                  {" "}
                  {props.dureeVidVis} &nbsp; <b>S</b>{" "}
                </span>
              </div>
              <div>
                <span> Points offerts : &nbsp; </span>{" "}
                <span> {props.gigaVis}</span>{" "}
              </div>

              <div>
                <span> Nom partenaire : &nbsp; </span>{" "}
                <span> {props.idPartVis} </span>{" "}
              </div>
              <div>
                <span> Nom usager : &nbsp; </span>{" "}
                <span> {props.idUsagerVis} </span>{" "}
              </div>
            </div>
          </div>

          <div>
            <div className="row1_details_header">
              <span style={{ color: "black" }}>
                <b> Détails Visionnage </b>{" "}
              </span>
              {/* <span> {props.partDescription} </span> */}
            </div>

            <div className="row1_details_details">
              <br />
              <span style={{ color: "black" }}>
                {" "}
                <b>Détails</b>
              </span>

              <div>
                <span> ID_vidéo : &nbsp; </span> <span> {props.idVidVis} </span>{" "}
              </div>
              <div>
                <span> Date Visionnage : &nbsp; </span>{" "}
                <span>
                  {" "}
                  {new Date(props.dateVis).toLocaleString("fr-CI", {
                    day: "numeric",
                    month: "numeric",
                    year: "2-digit",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                    timeZone: "Africa/Abidjan",
                  })}{" "}
                </span>{" "}
              </div>
              {/* <div>
                <span> Description Activite : &nbsp; </span>{" "}
                <span> {props.partDescription} </span>{" "}
              </div> */}

              <div className="row_status_div"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Details2 = (props) => {
  

    // const [displayItem, setDisplayItem] = useState(true)
    const [show, setShow] = useState(false);

    const deleteVideo=()=>{
      axios.delete(`${BACKEND_API_URL}/api/video/deleteVideo/${props.idVis}`)
      .then((res)=>{console.log(res)
      setShow(true)
      window.location.reload()
      })
      .catch((e)=>console.log(e))
    }
    const date = new Date(props.dateajout); // Crée une instance de l'objet Date à partir de la prop 'date'
  
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    };
    const formattedDate = `${date.toLocaleDateString('fr-FR', options)}`;
    const blockVideo =(id)=>{
      axios.put(`${BACKEND_API_URL}/api/video/video/${id}/block`)
      .then((res)=>{console.log(res)
        window.location.reload();
      
      })
      .catch((e)=>console.log(e))
      console.log("ok block")
  
    }
    const unBlockVideo =(id)=>{
      axios.put(`${BACKEND_API_URL}/api/video/video/${id}/unblock`)
      .then((res)=>{
        console.log(res)
      window.location.reload();
        
      })
      .catch((e)=>console.log(e))
      console.log("ok unblock")
    }
    const handleBlockClick = (id) => {
      blockVideo(id);
  
    }
    const handleUnBlockClick = (id) => {
      unBlockVideo(id);
      // window.location.reload();
  
    }
    return (
      <div className="sect3_elem_details">
        <div>
          {/* <FontAwesomeIcon  style={{color:"black"}}  icon="fa-solid fa-circle-xmark" /> */}
          <div onClick={props.onClose} className="elem_details_closure">
            {" "}
            <i className="fa-solid fa-xmark"></i>{" "}
          </div>
  
          <div className="part_details_div">
            <div>
              <div className="row1_details_header">
                <span style={{ color: "black" }}>
                  <b> Détails Video </b>{" "}
                </span>
                {/* <span> {props.partDescription} </span> */}
                {show && (
                
                <Alert
                      severity="success"
                      style={{ position: "relative", left: 400, bottom: 150 }}
                    >
                      Video bien supprimer
                    </Alert>
                  )}
              </div>
  
              <div className="row1_details_details">
                <br />
  
                <span style={{ color: "black" }}>
                  {" "}
                  <b>Détails</b>
                </span>
  
                <div>
                  <span> ID : &nbsp; </span> <span> {props.idVis} </span>{" "}
                </div>
              
                <div>
                  <span> Intitule Video : &nbsp; </span>{" "}
                  <span> {props.intitulee} </span>{" "}
                </div>
                
                <div>
                  <span> Duree Video : &nbsp; </span>{" "}
                  <span> {props.duree} </span>{" "}
                </div>
                <div>
                  <span> Point Donnée : &nbsp; </span>{" "}
                  <span> {props.nbDatas} </span>{" "}
                </div>
                <div>
                  <span> Type PackVideo : &nbsp; </span>{" "}
                  <span> {props.packVideo} </span>{" "}
                </div>
                
              </div>
            </div>
  
            <div>
              <div className="row1_details_header">
                <span style={{ color: "black" }}>
                  <b> Détails Video </b>{" "}
                </span>
                {/* <span> {props.partDescription} </span> */}
              </div>
  
              <div className="row1_details_details">
                <br />
                <span style={{ color: "black" }}>
                  {" "}
                  <b>Détails</b>
                </span>
  
                <div>
                  <span> Nom Partenaire : &nbsp; </span> <span> {props.nomPartenaire} </span>{" "}
                </div>
               
                {/* <div>
                  <span> Description Activite : &nbsp; </span>{" "}
                  <span> {props.partDescription} </span>{" "}
                </div> */}
  
                <div className="row_status_div">
                <span> Date Ajout: &nbsp; </span> 
                <span> {formattedDate} </span>{" "}
  
                </div>
                <div className="row_status_div">
                <span> Montant Pour Video: &nbsp; </span> 
                <span> {props.prixCampagneVideo} </span>{" "}
  
                </div>
                <div className="row_status_div">
                <span> Statu video: &nbsp; </span> 
                <span> {props.statut} </span>{" "}
  
                </div>
                {/* <div className="row_status_div" >
                  <span>Choisir le statut:</span>
                  <button style={{width:"80px",height:'25px',borderRadius:7,border:'none',backgroundColor:'red',fontWeight:'bold',color:"#ffff",cursor:"pointer"}} onClick={()=>handleBlockClick(props.idVis)}>Bloquer</button>
                  <button style={{width:"90px",height:'25px',borderRadius:7,border:'none',backgroundColor:'green',fontWeight:'bold',color:"#ffff",cursor:'pointer'}} onClick={()=>handleUnBlockClick(props.idVis)}>Debloquer</button>
                </div> */}
              </div>
              <button style={{marginTop:400,height:30,width:100,borderRadius:7,border:"none",fontWeight:'bold',color:"#ffff",backgroundColor:'red', cursor:'pointer'}} onClick={()=>deleteVideo()}>Supprimer</button>
            </div>
          </div>
        </div>
      </div>
    );

};

const Sect3 = () => {
  const [displayItem, setDisplayItem] = useState(false);
  const [displayItem2, setDisplayItem2] = useState(false);
  const [loading, setLoading] = useState(true);

  const [currVisionnage, setCurrVisionnage] = useState({});
  const [currHommeSandwich, setCurrHommeSandwich] = useState({});

  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(6);
  const [itemsPerPage2, setItemsPerPage2] = useState(6);
  const [searchTerm2, setSearchTerm2] = useState("");
  const [currentPage2, setCurrentPage2] = useState(1);
  // const [itemsPerPage2] = useState(5);
  const [totalPages2, setTotalPages2] = useState(0);
  const [cookies2] = useCookies(["giga_partenaire"]);

  const closeChild = () => {
    setDisplayItem(false);
  };

  const closeChild2 = () => {
    setDisplayItem2(false);
  };

  const [visionnages, setVisionnages] = useState([]);
  const [hommesSandwich, setHommesSandwich] = useState([]);

  const partenaireId = cookies2.giga_partenaire.idPartenaire;

  useEffect(() => {
    setLoading(true);
    const limit1 = itemsPerPage2;
    const page = currentPage2;
    Promise.all([
      axios.get(
        `${BACKEND_API_URL}/api/visionnages/partenaire/${partenaireId}`,
        {
          params: {
          limit: limit1,
          page: page,
          search: searchTerm2,

          }

        }
      ),
      axios
        .get(`${BACKEND_API_URL}/api/video/getVideosByPartenaire2/${partenaireId}`, {
     
          params: {
            limit: itemsPerPage2,
            page: page,
            searchTerm: searchTerm2
          }
        })
      // axios.get("${BACKEND_API_URL}/api/AddPartenaire"),
    ])

      .then(([dataVisionnage, dataHommeSandwich]) => {
        setVisionnages(dataVisionnage.data.viewings);

        // console.log("hey visios", dataHommeSandwich.data);

        setHommesSandwich(dataHommeSandwich.data.videos);
        // console.log(hommesSandwich);
        setTotalPages2(dataHommeSandwich.data.totalPages);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleVisionnage = (visionnage) => {
    setCurrVisionnage(visionnage);
    setDisplayItem(true);
  };

  const handleHommeSandwich = (hommeSandwich) => {
    setCurrHommeSandwich(hommeSandwich);
    setDisplayItem2(true);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  

  // const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // // for homme sandwich
  // const indexOfLastItem2 = currentPage2 * itemsPerPage2;
  // const indexOfFirstItem2 = indexOfLastItem2 - itemsPerPage2;
  // let currentItems2 = hommesSandwich
  //   .filter(
  //     (hs) => hs.email.toLowerCase().includes(searchTerm2.toLowerCase())
  //     // hs.telUsager.toLowerCase().includes(searchTerm2.toLowerCase()) ||
  //     // hs.idUsager.toLowerCase().includes(searchTerm2.toLowerCase())
  //   )
  //   .slice(indexOfFirstItem2, indexOfLastItem2);
  // console.log("currtItems: " + currentItems2);

  // const paginate2 = (pageNumber) => setCurrentPage2(pageNumber);

  // const paginate2 = (pageNumber) => {
  //   setCurrentPage2(pageNumber);
  //   setLoading(true);
  //   const limit1 = itemsPerPage2;
  //   const page = pageNumber;
  //   console.log("page: " + pageNumber);
  //   Promise.all([
  //     // axios.get(`${BACKEND_API_URL}/api/visionnages?limit=${limit1}&page=${page}`),
  //     axios.get(
  //       `${BACKEND_API_URL}/api/AddPartenaire?limit=${limit1}&page=${page}`
  //     ),
  //   ])
  //     .then(([dataHommeSandwich]) => {
  //       // setVisionnages(dataVisionnage.data);
  //       setHommesSandwich(dataHommeSandwich.data.allAddPartenaire);
  //       // console.log(dataHommeSandwich.data.allAddPartenaire);
  //       setTotalPages2(dataHommeSandwich.data.totalPages);
  //       // alert(totalPages2)
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });

  //   currentItems2 = hommesSandwich;
  //   console.log("currtItems: " + currentItems2);
  // };

  const searchHandler2 = () => {
    fetchVisionnages(searchTerm, 1);
  };
  
  
    const fetchVisionnages = (searchTerm, page) => {
      setLoading(true);
      axios
        .get(`${BACKEND_API_URL}/api/video/getVideosByPartenaire2/${partenaireId}`, {
     
          params: {
            limit: itemsPerPage2,
            page: page,
            searchTerm: searchTerm2
          }
        })
        .then((response) => {
          setVisionnages(response.data.videos);
          // setTotalPages(response.data.totalPages);
          // console.log("totalPages: ", response.data.totalPages);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    };
  
  

  return (
    <div className="sect3">
      <div>
        <div className="sect3_top">
          <div>Visionnages récents</div>
          {/* <div className="search-bar-container">
            <input
              type="text"
              placeholder="Rechercher..."
              className="search-bar"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div> */}
        </div>

        <div className="sect_table">
          <table>
            <thead>
              <tr>
                <th>##</th>
                <th>Intitule Video</th>
                <th>Duree Video</th>
                <th>Points offerts</th>
                <th>Nom Usager</th>
                <th>Nom Partenaire</th>
                <th> Action </th>
              </tr>
            </thead>
            <tbody>
              {loading && (
                <ScaleLoader
                  style={{
                    position: "absolute",
                    top: "70vh",
                    left: "25%",
                  }}
                  color="#4EAC04"
                />
              )}

              {visionnages.length === 0 ? (
                <tr>
                  <td
                    colSpan="5"
                    style={{
                      textAlign: "center",
                      color: "black",
                      fontSize: "20px",
                      fontWeight: "bold",
                      position: "relative",
                      backgroundColor: "white",
                      // top:"10px",
                      left: "80px",
                    }}
                  >
                    {" "}
                    Aucun visionnage enregistré pour l'instant
                  </td>
                </tr>
              ) : (
                visionnages.map((visionnage, index) => (
                  <tr key={visionnage._id}>
                    <td>{visionnages.length - index}</td>
                    <td> {visionnage.video?.intitulee} </td>
                    <td>{visionnage.video?.duree} </td>
                    <td>{visionnage.pointDonnee}</td>
                    <td>{visionnage.usager?.nom ?? "Null"}</td>
                    <td>{visionnage.partenaire?.nomPartenaire ?? "NULL"}</td>
                    <td>
                      <Tooltip placement="right" title="Détails">
                        <i
                          onClick={() => handleVisionnage(visionnage)}
                          className="fa-solid fa-tv"
                        ></i>
                      </Tooltip>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
          <div className="pagination">
            <a href="/visionnages" className="btn_see_more">
              Voir plus
            </a>
          </div>
        </div>
      </div>

      <div>
        <div className="sect3_top2">
          <div className="hs_top_titre">
            <span>Mes vidéos</span>
          </div>
          <div className="search-bar-container">
            <input
              type="text"
              placeholder="Aller sur la page 'Mes données.....' "
              className="search-bar-hs"
              value={searchTerm2}
              onChange={(e) => setSearchTerm2(e.target.value)}
              disabled={true}
            />
            <button
              onClick={searchHandler2}
              disabled={
                (loading && searchTerm2.length > 0) ||
                (!loading && searchTerm2.length == 0)
                  ? true
                  : false
              }
              className={
                (loading && searchTerm2.length > 0) ||
                (!loading && searchTerm2.length == 0)
                  ? "search-button-disabled"
                  : "search-button"
              }
            >
              <i className="fa fa-search"></i>
            </button>
          </div>
        </div>

        <div className="sect_table">
          <table>
            <thead>
            <tr>
                <th>ID</th>
                <th>Intitule video</th>
                <th>Total vues</th>
                <th>Durée vidéo</th>
                <th> Action </th>
              </tr>
            </thead>
            <tbody>
              {/* <tr>
              <td>1</td>
              <td>58</td>
              <td>lewisaliv@gmail.com</td>
              <td>0767567930</td>
              <td> <i className='fa-solid fa-eye' ></i></td>
            </tr> */}

              {loading && (
                <ScaleLoader
                  style={{
                    position: "absolute",
                    top: "70vh",
                    left: "72%",
                  }}
                  color="#4EAC04"
                />
              )}

{hommesSandwich.length === 0 ? (
                <tr>
                  <td
                    colSpan="5"
                    style={{
                      textAlign: "center",
                      color: "black",
                      fontSize: "20px",
                      fontWeight: "bold",
                      position: "relative",
                      backgroundColor: "white",
                      // top:"10px",
                      left: "80px",
                    }}
                  >
                    {" "}
                    Aucune vidéo ajoutée pour l'instant
                  </td>
                </tr>
              ) : (
                hommesSandwich.map((hs, index) => (
                  <tr key={hs._id}>
                  <td>{hs._id}</td>
                  <td>{hs.intitulee}</td>
                  <td>{hs.visionnages.length}</td>
                  <td>{hs.duree}</td>
                  <td>
                    <i onClick={() => handleHommeSandwich(hs)} className="fa-solid fa-tv"></i>
                  </td>
                </tr>
                ))
              )}

            </tbody>
          </table>

          <div className="pagination-hs">
            <a href="/visionnages" className="btn_see_more">
              Voir plus
            </a>
          </div>
        </div>
      </div>

      {displayItem && (
        <Details
          idVis={currVisionnage._id}
          idVidVis={currVisionnage.idVideo}
          idUsagerVis={currVisionnage.usager?.nom ?? "Null"}
          // idVidVis={currVisionnage.idVideo}
          gigaVis={currVisionnage.pointDonnee}
          opeVis={currVisionnage.OperateurDonneur}
          idPartVis={currVisionnage.partenaire?.nomPartenaire ?? "Null"}
          dureeVidVis={currVisionnage.duréeVideo}
          dateVis={currVisionnage.dateVisionnage}
          onClose={closeChild}
        />
      )}

      {displayItem2 && (
        <Details2
           idVis={currHommeSandwich._id}
              intitulee={currHommeSandwich.intitulee}
              duree={currHommeSandwich.duree}
              nbDatas={currHommeSandwich.totalPointDonnee}
              packVideo={currHommeSandwich.packVideoCopy?.nom ?? ""}
              gigaRestant={currHommeSandwich.gigaRestant}
              nomPartenaire={currHommeSandwich.partenaire?.nomPartenaire ?? "NULL"}
              dateajout={currHommeSandwich.dateAjout}
              statut={currHommeSandwich.statutVideo ? 'Activer' : 'Desactiver'}
              prixCampagneVideo={currHommeSandwich.prixCampagneVideo}
          onClose={closeChild2}
        />
      )}
    </div>
  );
};

export default Sect3;
